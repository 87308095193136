import * as React from 'react';
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import _ from "lodash";
import TextHelper from "../../shared/text-formatting";

const PearsonElementsSdk = require("@pearson-components/modal");
import UnitSelectionList from "./unit-selection-list";
import Unit from "../../../models/unit";
import { SampleRequest } from "../../../models/sample-request";

import ConfirmationModalGeneric from "../../shared/confirmation-modal-generic"
const pageTitle ="Unit Selection";
export interface StateProps {
  sampleRequest: SampleRequest;
  SVUnitDetails: Unit[]; 
}

export interface DispatchProps {
  getStandardVerificationUnits: (learningProviderId: string, qualificationId: string) => Promise<void>;
  ondeActivateRequest: (  standardsVerificationSampleId: string,
    assessmentAssociateId: string) => Promise<void>;
  onreactivateRequest: (  standardsVerificationSampleId: string,
    assessmentAssociateId: string) => Promise<void>;
  modifySelectedStandardsVerificationUnits: (
    unitDetails: object[],
    unitIds:string[],    
    action: string,
    standardsVerificationSampleId: string,
    assessmentAssociateId: string
  ) =>  Promise<void>;
}

export type OwnProps = RouteComponentProps<{ allocationId: string }>;

interface Props extends DispatchProps, StateProps, OwnProps {}

interface State {
  isBusy: boolean;
  selectedColumn: string;
  direction: string;
  searchText: string;
  deactivateModal:boolean;
  reactivateModal:boolean;
}

export class UnitSelection extends Component<Props, State> {
  onSort: void;

  constructor(props: Props) {
    super(props);
    document.title=pageTitle;
    this.state = {
      isBusy: true,
      selectedColumn: "",
      direction: "",
      searchText: "",
      deactivateModal: false,
      reactivateModal:false,
    };

    this.getUnitIds = this.getUnitIds.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSort = this.handleColumnSort.bind(this);
    this.getIconName = this.getIconName.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.onSelectCheckAll = this.onSelectCheckAll.bind(this);
    this.checkBoxAll = this.checkBoxAll.bind(this); 
    this.onSearch = this.onSearch.bind(this);
    this.ondeActivate= this.ondeActivate.bind(this)
    this.onreactivate= this.onreactivate.bind(this)
    this.getdeactivateModalConfirmationMsg= this.getdeactivateModalConfirmationMsg.bind(this)
    this.getreactivateModalConfirmationMsg= this.getreactivateModalConfirmationMsg.bind(this)
    this.canceldeactivateModalHandler= this.canceldeactivateModalHandler.bind(this)
    this.successdeactivateModalHandler= this.successdeactivateModalHandler.bind(this)
    this.cancelreactivateModalHandler= this.cancelreactivateModalHandler.bind(this)
    this.successreactivateModalHandler= this.successreactivateModalHandler.bind(this)
  }

  render() {
    const { isBusy, selectedColumn } = this.state;
    const text = {
      headerTitle: 'Deactivate Request',
      bodyText: '',
      closeButtonSRText: 'Cancel',
      modalSaveButtonText: 'Ok',
      modalCancelButtonText: 'Cancel'
    };
    const reActivateText = {
      headerTitle: 'Reactivate Request',
      bodyText: '',
      closeButtonSRText: 'Cancel',
      modalSaveButtonText: 'Ok',
      modalCancelButtonText: 'Cancel'
    }
    require('../search-modal/search-modal.css');
    return (
       <div className="unitselection">
        <div className="pe-template__single">
          <h1 className="pe-page-title">{pageTitle}</h1>
        </div>
        <div className="pe-template__single content">
          <section>
            <p className="pe-strategy__centered--small-gap">
              Select the unit(s) that must be sampled. Press 'Next' when you are
              ready to link learner(s) to the selected unit(s).
            </p>
            {!isBusy && this.props.sampleRequest && (<div>
              <div className="pe-row">
                <div className="pe-col-6">
                  <div className="pe-label small-gap">
                    <span className="pe-label pe-label--bold">
                      Learning Provider
                    </span>
                    <br />
                    {this.props.sampleRequest.parentLearningProviderCode ? this.props.sampleRequest.parentLearningProviderCode : this.props.sampleRequest.learningProviderCode}&nbsp; 
                    {this.props.sampleRequest.learningProviderName}&nbsp;
                    <b>{this.props.sampleRequest.consortiaId ? '(C)': '' }</b>
                  </div>
                </div>
                <div className="pe-col-6">
                  <div className="pe-label small-gap">
                    <span className="pe-label pe-label--bold">
                      Qualification
                    </span>
                    <br />
                    {this.props.sampleRequest.qualificationGroupCode}&nbsp; 
                    {this.props.sampleRequest.qualificationName}&nbsp;
                    <b className="second-sample-text-color">{this.props.sampleRequest.parentRequests && this.props.sampleRequest.parentRequests.length > 0 ? "("+(this.props.sampleRequest.parentRequests.length + 1)+")" : ""}</b>
                  </div>
                </div>
              </div>
              <div className="pe-row">
                <div className="pe-col-6">
                  <div className="pe-label small-gap">
                    <span className="pe-label pe-label--bold">Status</span>
                    <br />
                    {TextHelper.titleCaseUploadStatus(this.props.sampleRequest.status)}                    
                  </div>
                </div>
              </div>
              <div className="pe-row">
                <div className="pe-col-6">
                  <div className="pe-label small-gap">
                    <span className="pe-label pe-label--bold" />
                    <br />
                  </div>
                </div>
              </div>

            {this.props.SVUnitDetails.length > 0 && (
                <UnitSelectionList
                  units={
                    selectedColumn === ""
                      ? this.filter(this.props.SVUnitDetails)
                      : this.sort(this.props.SVUnitDetails)
                  }
                  onSelect={this.onSelect}
                  onSort={this.handleColumnSort}
                  getIconName={this.getIconName}
                  status = {this.props.sampleRequest && this.props.sampleRequest.status}
                  previousState ={ this.props.sampleRequest.previousState}
                  onFieldChange={this.onFieldChange}
                  goBack={this.goBack}
                  goNext={this.goNext}
                  ondeActivate ={this.ondeActivate}
                  onreactivate={this.onreactivate}
                  isDisabled={
                    this.disableNextButton()
                  }
                  selectedUnits={this.getUnitIds()}
                  onSelectCheckAll={this.onSelectCheckAll}
                  checkBoxAll={this.checkBoxAll()}
                  isTeamMember = {this.isTeamMember()}
                  
                />
              )}
             

            </div>)}
            <p>              
              <br />{isBusy && "Loading..."}              
            </p>
      
          </section>
        </div>
        <PearsonElementsSdk.Modal
          isShown={this.state.deactivateModal}
          text={text}
          footerVisible={false}
          cancelBtnHandler={this.canceldeactivateModalHandler}
          successBtnHandler={this.successdeactivateModalHandler}
        >
           <ConfirmationModalGeneric 
            cancelBtnHandler={this.canceldeactivateModalHandler}
            successBtnHandler={this.successdeactivateModalHandler} 
            conmfirmMsgRender = {this.getdeactivateModalConfirmationMsg}
          />
        </PearsonElementsSdk.Modal>
          <PearsonElementsSdk.Modal
          isShown={this.state.reactivateModal}
          text={reActivateText}
          footerVisible={false}
          cancelBtnHandler={this.cancelreactivateModalHandler}
          successBtnHandler={this.successreactivateModalHandler}
        >
           <ConfirmationModalGeneric 
            cancelBtnHandler={this.cancelreactivateModalHandler}
            successBtnHandler={this.successreactivateModalHandler} 
            conmfirmMsgRender = {this.getreactivateModalConfirmationMsg}
          />
        </PearsonElementsSdk.Modal>
      </div>)
  }

  componentDidMount() {
    // Get data to load into page from qualification id passed in as part of the route   
    this.handleColumnSort('name');
    if (this.props.sampleRequest) {
      
      this.setState({isBusy: true});
      this.props.getStandardVerificationUnits(
        this.props.sampleRequest.learningProviderId,
        this.props.sampleRequest.qualificationId
      ).then(() => this.setState({isBusy: false }));  
    }
  }

  componentDidUpdate(prevProps: Props) {
    this.handleColumnSort('name');
    if (!prevProps.sampleRequest && this.props.sampleRequest) {
      this.setState({isBusy: true});
      this.props.getStandardVerificationUnits(
        this.props.sampleRequest.learningProviderId,
        this.props.sampleRequest.qualificationId
      ).then(() => this.setState({isBusy: false }));  
    }
  }  

  onSearch()
  {
    const searchTerm = this.state.searchText;
    let filteredSVUnitDetails = this.props.SVUnitDetails;
    if (searchTerm !== "" && searchTerm.length >= 3) {
      filteredSVUnitDetails = this.filter(this.props.SVUnitDetails);
    }
    return filteredSVUnitDetails;
  }
  ondeActivate = () => {
    return this.setState({ deactivateModal : true})
    
   }
 
   onreactivate= () => {
     return this.setState({ reactivateModal : true})
   }

   canceldeactivateModalHandler(){
    this.setState({ deactivateModal : false})
  }

  successdeactivateModalHandler(){
   
    this.props.ondeActivateRequest(
      this.props.sampleRequest.standardsVerificationSampleId,
      this.props.sampleRequest.assessmentAssociateId,      
      ).then(() =>{ 
        this.setState({ deactivateModal : false })
        this.props.history.push(`/sample-requests`);      
      });  
   
  }

  cancelreactivateModalHandler(){
    this.setState({ reactivateModal : false})
  }

  successreactivateModalHandler(){
   
    this.props.onreactivateRequest(
      
      this.props.sampleRequest.standardsVerificationSampleId,
      this.props.sampleRequest.assessmentAssociateId,
      ).then(() =>{ 
        this.setState({reactivateModal : false })
        //this.props.history.push(`/sample-requests`)
      });    
        
}

  getdeactivateModalConfirmationMsg(){
    return (<div>
      <p>
      This request will be deactivated and cannot be used to request materials from the Learning Provider. <br/>
      </p>
      <br/>
      <span>Do you want to proceed?</span>
    </div>);
  }

  getreactivateModalConfirmationMsg(){
    return (<div>
      <p>
     This request will be reactivated and can be used to request materials from the Learning Provider.
      </p>
      <br/>
      <span>Do you want to proceed?</span>
    </div>);
  }

  

  onSelectCheckAll(event: React.MouseEvent<HTMLSpanElement, MouseEvent>){
      const filteredSVUnitDetails = this.onSearch();

      const unitDetails:object[] = [];
      const unitIds:string[] = [];
      // if(this.props.SVUnitDetails.length !== this.getUnitIds().length){
      if(filteredSVUnitDetails.length !== this.getUnitIds().length){
        // this.props.SVUnitDetails.map(element=>{
          filteredSVUnitDetails.forEach(element=>{
          const unitDetailsObj = Object.assign({unitId:element.unitId.toString(),unitName:element.name});
          unitDetails.push(unitDetailsObj)
        })
        
      }else{
        // this.props.SVUnitDetails.map(element=>{
          filteredSVUnitDetails.forEach(element=>{
          unitIds.push(element.unitId.toString())
        })
      }
      // this.props.modifySelectedStandardsVerificationUnits(
      //   unitDetails,
      //   unitIds,
      //   (this.props.SVUnitDetails.length === this.getUnitIds().length) ? "removeunit" : "addunit", 
      //   this.props.sampleRequest.allocationId,
      //   this.props.sampleRequest.assessmentAssociateId
      // );
      if(!this.isTeamMember()){
      this.props.modifySelectedStandardsVerificationUnits(
        unitDetails,
        unitIds,
        (filteredSVUnitDetails.length === this.getUnitIds().length) ? "removeunit" : "addunit", 
        this.props.sampleRequest.allocationId,
        this.props.sampleRequest.assessmentAssociateId
      );
      }
  }
  checkBoxAll(){
    const filteredSVUnitDetails = this.onSearch();
    return (filteredSVUnitDetails.length === this.getUnitIds().length);
    
  }

  getUnitIds(){
    return this.props.sampleRequest.selectedUnits.map(lu => lu.unitId);
  }

  onSelect(
    unitId: string,
    unitName: string,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) {
    event.preventDefault();
    // GraphQL param is standardsVerificationSampleId.
    // But allocationId is used in place of standardsVerificationSampleId 
    // since standardsVerificationSampleId is coming as undefined
    //  -- Souvik
    let unitDetails:object[] = [];
    const unitIds:string[] = [];
    this.getUnitIds().includes(unitId) ? unitIds.push(unitId) :unitDetails = [Object.assign({unitId,unitName})] ;
    if(!this.isTeamMember()){
    
    this.props.modifySelectedStandardsVerificationUnits(
      unitDetails,
      unitIds,
      this.getUnitIds().includes(unitId) ? "removeunit" : "addunit", 
      this.props.sampleRequest.allocationId,
      this.props.sampleRequest.assessmentAssociateId
    );
    }
  }

  goBack() {
    this.props.history.push(`/sample-requests`);
  }

  goNext = () => {
    this.props.history.push(`/sample-requests/unit-selection/${this.props.sampleRequest.allocationId}/link-learners?display=false`);
  }

  onFieldChange(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault();
    const element = event.target as HTMLInputElement;
    return this.setState({ searchText: element.value.toLowerCase() });
  }

  getIconName = (selectedColumn: string) => {
    if (this.state.selectedColumn === selectedColumn) {
      return this.state.direction === "up" ? "sort-up-18" : "sort-down-18";
    }

    return "sortable-18";
  };

  handleColumnSort = (selectedColumn: string) => {
    return () => {
      let direction = "up";

      if (this.state.selectedColumn === selectedColumn) {
        direction = this.state.direction === "up" ? "down" : "up";
      }

      this.setState({ selectedColumn, direction });
    };
  };

  filter(StandardsVerificationUnits: Unit[]): Unit[] {
    const searchTerm = this.state.searchText;
    if (searchTerm !== "" && searchTerm.length >= 3) {
      const SVUnits = JSON.parse(JSON.stringify(StandardsVerificationUnits));
      const columnNames = ["name", "mandatory"];
      return _.filter(SVUnits, SVUnit => {
        const v2 = _(SVUnit)
          .pick(columnNames)
          .some(searchstring => {
            return _(searchstring)
              .toLower()
              .includes(searchTerm);
          });
        return v2;
      }) as Unit[];
    }
    return StandardsVerificationUnits;
  }

  sort(StandardsVerificationUnits: Unit[]): Unit[] {
    const lodashDirection = this.state.direction === "down" ? "desc" : "asc";

    const sortedSVUnits = _.orderBy(
      StandardsVerificationUnits,
      [
        SVUnit => {
          if (
            SVUnit[this.state.selectedColumn] && 
            typeof SVUnit[this.state.selectedColumn].toLowerCase === "string"
          ) {
            return SVUnit[this.state.selectedColumn].toLowerCase;
          } else {
            return SVUnit[this.state.selectedColumn];
          }
        }
      ],
      [lodashDirection]
    ) as Unit[];
    return this.filter(sortedSVUnits);
  }

  disableNextButton():boolean {    
      let ret = true;
      this.props.SVUnitDetails.forEach(elem => {                
        // const res = this.getUnitIds().filter((o) => o === elem.unitId+'');
        const res = this.getUnitIds().filter((o) => o === elem.unitId+'');
        if(res.length>0) {
          ret = false;
        }
      })   
    return ret;     
  }
  isTeamMember():boolean {    
    let isTeamMember = false;
    const proxyAATeamMember = localStorage.getItem('isTeamMember')
    if(proxyAATeamMember === 'true'){
      isTeamMember =true;
    }
    return isTeamMember;
  }

  
}

export default UnitSelection;
