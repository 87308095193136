import { connect} from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  Callback,
  CallbackProps,
  DispatchProps
} from './callback';

import { loading, CallbackAction, callbackComplete } from '../../actions/callback.actions'
import { signoutUser, handleAuthentication, AuthAction } from '../../actions/auth/auth.actions'

import { StoreState } from '../../store/store-state';

const mapStateToProps = (
  state: StoreState
) : CallbackProps => ({
    loading: state.callback.loading,
    error: state.callback.error
});

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void, CallbackAction | AuthAction>): DispatchProps => ({
  callbackInProgress: () => dispatch(loading()),
  handleAuthentication: (callback) => dispatch(handleAuthentication(callback)),
  signoutUser: () => signoutUser(dispatch),
  callbackComplete: () => dispatch(callbackComplete())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Callback);