export type Nullable<T> = T | null

// export type AssessmentAssociateType = AssessmentAssociate | null;

export class AssessmentAssociate {
  id: string;
  aaNumber: string;
  title: string;
  firstName: string;
  surname: string;
  username: string;

  constructor(id: string, aaNumber: string, username: string, firstName: string, surname: string) {
    this.id = id;
    this.aaNumber = aaNumber;
    this.username = username;
    this.firstName = firstName;
    this.surname = surname;
  }
}