import * as React from 'react';
// const PearsonElementsSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');
import {Button,Table} from 'ukas.uux.react-components';
// import AssessmentAssociateAvailabilityListRow from './sample-standards-verification-requests-list-row';
// import { SampleRequest } from '../../../models/sample-request';
import TeamMemberListRow from './team-member-list-row';


interface TeamMemberListProps {
  onSelect: (
    assessmentAssociateId: string,
    AATeamMember:object,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  teamMemberList: any;
  onSort: (item: string) => void;
  getIconName: (columnName: string) => string;
  closeEvent: any;
}

const TeamMemberList = ({
  onSelect,
  teamMemberList,
  onSort,
  getIconName,
  closeEvent
}: TeamMemberListProps) => {
        
      
  return (
    <div>
      <Table >
        <thead>
          <tr  >
            <th>
              AA Details
            </th>            
          </tr>
        </thead>
        <tbody>
        {teamMemberList.map((teamMembersDetails: any, i:number) => (
          <TeamMemberListRow 
            key={i}             
            onTeamMemberSelect={onSelect}
            teamMember= {teamMembersDetails}            
          /> )        
        )}
        </tbody>
      </Table>
      <div className="pull-right"><br />
      <Button
        onClick={closeEvent}
        variant="primary"       
      >
        Cancel
      </Button>
    </div>
    </div>
  );
};

export default TeamMemberList;
