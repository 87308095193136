import axios, { AxiosRequestConfig } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import Learners from '../../../../models/learner';
import * as action_types from '../../../../actions/action-types';
import {
  ajaxCallError,
  beginAjaxCall,
  AjaxCallActions
} from '../../../../actions/ajax-status.actions';

import { updateSampleStandardsVerificationRquestsSuccessAction, SampleStandardsVerificationRequestsAction } from "../../../shared/sample-standards-verification-request.actions";

import config from '../../../../app-config';
import { StoreState } from '../../../../store/store-state';
import { SampleRequest } from '../../../../models/sample-request';

import AuthForgeRock from '../../../../actions/auth/auth';
const auth = new AuthForgeRock();

interface GetStandardVerificationLearnersSuccessAction extends Action {
  type: action_types.GET_STANDARDS_VERIFICATION_LEARNERS_SUCCESS,
  SVLearnerDetails: Learners[];
}

export type SVLearnerDetailsAction =
  GetStandardVerificationLearnersSuccessAction
  | SampleStandardsVerificationRequestsAction
  | AjaxCallActions;

type ThunkResult<R> = ThunkAction<
  R,
  StoreState,
  undefined,
  SVLearnerDetailsAction
>;

export const getStandardVerificationLearnersSuccessAction = (
  SVLearnerDetails: Learners[]
): GetStandardVerificationLearnersSuccessAction => ({
  SVLearnerDetails,
  type: action_types.GET_STANDARDS_VERIFICATION_LEARNERS_SUCCESS

});

export const addLearnerInfoInput = (newLearnerSet: any): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());
    const axiosConfig: AxiosRequestConfig = {
      headers: { 
        "content-type": "application/json",
        "Authorization": `Bearer ${auth.getAccessToken()}`,
        'x-id-token': `${auth.getIdToken()}`
      }
    };
    return axios
      .post(`${config.API_GATEWAY.URL}/learner-selection/add-learner`,
        JSON.stringify(newLearnerSet), axiosConfig)
      .then(postResponse => {
        const sampleRequest = postResponse.data as SampleRequest;
        dispatch(updateSampleStandardsVerificationRquestsSuccessAction(sampleRequest));

      })
      .catch(error => {
        dispatch(ajaxCallError(error)); 
        throw error;
      });
  }
};

export const removeLearnerInfoInput = (newLearnerSet: any): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());
    const axiosConfig: AxiosRequestConfig = {
      headers: { 
        "content-type": "application/json",
        "Authorization": `Bearer ${auth.getAccessToken()}`,
        'x-id-token': `${auth.getIdToken()}`
      }
    };
    return axios
      .post(`${config.API_GATEWAY.URL}/learner-selection/remove-learner`,
        JSON.stringify(newLearnerSet), axiosConfig)
      .then(postResponse => {
        const sampleRequest = postResponse.data as SampleRequest;
        dispatch(updateSampleStandardsVerificationRquestsSuccessAction(sampleRequest));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  }
};

export const getStandardVerificationLearners = (
  learningProviderId: string,
  qualificationId: string,
  unitId: string,
  academicYear: string
): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());
    const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}`}
    return axios
      .get(`${config.API_GATEWAY.URL}/learner-selection/learners/${learningProviderId}/${qualificationId}/${unitId}/${academicYear}`, {headers})
      .then(response => {
        const SVLearnerDetails = response.data;
        // console.log("ACTION============",JSON.stringify(SVLearnerDetails));
        dispatch(
          getStandardVerificationLearnersSuccessAction(

            SVLearnerDetails.map((SVLearnerDetail: Learners) => {
              const svlearners = new Learners();

              svlearners.learnerId = SVLearnerDetail.learnerId;
              svlearners.learningProviderId = SVLearnerDetail.learningProviderId;
              svlearners.qualificationId = SVLearnerDetail.qualificationId;
              svlearners.firstName = SVLearnerDetail.firstName;
              svlearners.surname = SVLearnerDetail.surname;
              svlearners.code = SVLearnerDetail.code
              svlearners.dateOfBirth = SVLearnerDetail.dateOfBirth
              svlearners.gender = SVLearnerDetail.gender
              svlearners.learningProviderCode=SVLearnerDetail.learningProviderCode
              svlearners.learningProviderName=SVLearnerDetail.learningProviderName


              return svlearners;
            })
          )
        );
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};


export const getStandardVerificationLearnersByconsortiaId = (
  consortiaId: number,
  qualificationId: string,
  academicYear:string
): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());
    const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}`}
    return axios
      .get(`${config.API_GATEWAY.URL}/learner-selection/learnersByConsortiaId/${consortiaId}/${qualificationId}/${academicYear}`, {headers})
      .then(response => {
        const SVLearnerDetails = response.data;
        // console.log("ACTION============",JSON.stringify(SVLearnerDetails));
        dispatch(
          getStandardVerificationLearnersSuccessAction(

            SVLearnerDetails.map((SVLearnerDetail: Learners) => {
              const svlearners = new Learners();
              // console.log(SVLearnerDetail)
              svlearners.learnerId = SVLearnerDetail.learnerId;
              svlearners.learningProviderId = SVLearnerDetail.learningProviderId;
              svlearners.qualificationId = SVLearnerDetail.qualificationId;
              svlearners.firstName = SVLearnerDetail.firstName;
              svlearners.surname = SVLearnerDetail.surname;
              svlearners.code = SVLearnerDetail.code
              svlearners.dateOfBirth = SVLearnerDetail.dateOfBirth
              svlearners.gender = SVLearnerDetail.gender
              svlearners.learningProviderCode = SVLearnerDetail.learningProviderCode
              svlearners.learningProviderName = SVLearnerDetail.learningProviderName
              return svlearners;
            })
          )
        );
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};
