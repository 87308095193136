import * as React from 'react';
import {Button} from 'ukas.uux.react-components';
// const PearsonElementsSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');
interface ConfirmationModalProps {
    cancelBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
    successBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
}


const ConfirmationModal = ({ successBtnHandler, cancelBtnHandler }: ConfirmationModalProps) =>{
  return ( 
    <div>
      <div >
      <span>You are confirming:</span>
          <ul>
            <li>The required unit(s) and learner(s) are part of the sample request.</li>
            <li>Submitting will finalise the sample request that will be seen by the learning provider.</li>
          </ul>
          <span>Do you want to proceed?</span>
      </div>
      
      <div className="pull-right"><br /><br />
      <Button
            onClick={cancelBtnHandler.bind(event)}
            
            // disabled={isDisabled ? "disabled" : ""}
            >
            Cancel
            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="primary" onClick={successBtnHandler.bind(event)}>
            Ok
        </Button>
        <br /><br />
            
        
      </div>
    </div>
     
      
   
  );
};

export default ConfirmationModal;
