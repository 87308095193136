import * as types from '../../actions/action-types';
import  { UserDetailsAction }  from './user.actions';
import  User, {} from '../../models/user';
import AATeam from '../../models/team';


import initialState from '../../reducers/initialState';

export function GetUserDetailsReducer(
  state: User = initialState.userDetails,
  action: UserDetailsAction
): User {
  if (action.type === types.GET_USER_DETAILS_SUCCESS) {
   
        return action.userDetails;
  }
   else{
      return state;
  }
}

export function ValidateUserPermissionsReducer(
  state: boolean = initialState.userPermissionValidated,
  action: UserDetailsAction
): boolean {
  if (action.type===  types.VALIDATE_USER_PERMISSIONS_SUCCESS) {    
           return action.userPermissionValidated;
  }
        else{
          return state;
      }
}

export function GetEnvReducer(
  state: boolean = initialState.showCreateMenu,
  action: UserDetailsAction
): boolean {
  if (action.type === types.GET_ENV_SUCCESS) {    
   
        return action.e;
  }
        else{
          return state;
      }
  
}

export function selectAATeamReducer(
  state: string = initialState.selectedAATeamName,
  action: UserDetailsAction
): string {
  if(action.type ===types.SELECT_AA_TEAM_SUCCESS){

      return action.selectedAATeamName
  }
    else {
      return state;
  }
}

export function GetAATeamLeadDetailsReducer(
  state: AATeam[] = initialState.aaTeams,
  action: UserDetailsAction 
): AATeam[] {
  if (action.type ===types.GET_AA_TEAM_LEAD_DETAILS_SUCCESS) {
      
      return action.aaTeams;    
  }
    else {
      return state;
  } 
}


export function IsAATeamLeadReducer(
  state: boolean = initialState.isAATeamLead,
  action: UserDetailsAction 
): boolean {
  // console.log("IsAATeamLeadReducer--", action);  
  if (action.type === types.IS_AA_TEAM_LEAD_SUCCESS) {
    
      return action.isAATeamLead;  
  }  
    else
    {
      return state;
  } 
}
