import * as React from "react";
import Unit from "../../../models/unit";
import { SelectedLearner } from "../../../models/learner";


interface LinkLearnersListRowProps {
  unit: Unit;
  selectedLearners: SelectedLearner[]
  showLearners: (unitId: string, unitName: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    
}

const LinkLearnersListRow = ({
  unit,
  selectedLearners,
  showLearners
}: LinkLearnersListRowProps) => (
    <tr>
      <td>
        {unit.name}
      </td>
      <td>{selectedLearners ? selectedLearners.length : "0"} learner(s) linked
      </td>
      <td>
        <a href="#"
          onClick={showLearners.bind(event, unit.unitId, unit.name)}>
          Link learner(s)</a>

      </td>
    </tr>

  );



export default LinkLearnersListRow;
