import { LearnerUnit } from "./LearnerUnit";

export class MediaRequest {
  mediaSetId: string='';
  learningProviderId: string='';
  learningProviderCode: string= '';
  learningProviderName: string='';
  standardsVerifierId: string='';
  standardsVerifierName: string='';
  qualificationId: string='';
  qualificationName: string='';
  deadlineDateTime: Date = new Date();
  purpose: string='';
  samplesPerLearner: number=0;
  learnerUnits: LearnerUnit[];
  numberOfLearners: number=0;
  numberOfFiles: number=0;
  mediaSampleType: string = 'NONE';
  status: string;
  files: MediaRequestFile[]
  submissionDate: Date = new Date();
  submittedBy: string;

  constructor() {
    this.learnerUnits = [];
    this.files =[];
  }
}

export class MediaRequestFile {
  fileId: string='';
  unitId: string='';
  learnerIds: string[];
  orgFileName: string= '';
  fileName: string='';
  fileStatus: string='';
  createdDateTime: Date = new Date();
  fileSize: string;
  fileSizeBytes?: number;
  transformedFileKey: string='';
  transformedFileSize: string;

  constructor() {
    this.learnerIds = [];
  }
}