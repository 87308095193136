import * as React from 'react';
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import _ from "lodash";
import TextHelper from "../../shared/text-formatting";
const PearsonElementsSdk = require('@pearson-components/modal');
import ConfirmationModal from './confirmation-modal'
import RecallConfirmationModal from './recall-confirmation-modal'
import ResetConfirmationModal from './reset-confirmation-modal'

import LinkLearnersList, { UnitWithLearners } from "./link-learners-list";
import Unit, { SelectedUnit } from "../../../models/unit";
import { SelectedLearner } from "../../../models/learner";
import { SampleRequest } from "../../../models/sample-request";
import LearnerSelection from '../learner-selection/learner-selection-list.container.component';
import { AssessmentAssociate } from '../../../models/assessment-associate';
import { FormattedDate,FormattedTime } from "react-intl";

// import * as toastr from 'toastr';
import ErrorAlert from '../../../components/common/error-alert';
import SuccessAlert from '../../../components/common/success-alert';
import { GlobalAlertMessage } from "../../../models/global-alert-message";

const pageTitle ="Link Learners";

export interface StateProps {
  sampleRequest: SampleRequest;
  SVUnitDetails: Unit[];
  assessmentAssociate: AssessmentAssociate

}

interface SubmitSample {
  assessmentAssociateId: string;
  standardsVerificationSampleId: string;
  sampleSubmittedBy?: string;
}

export interface DispatchProps {
  submitSampleToLearningProvider: (submitSampleObj: SubmitSample) => Promise<void>;
  recallSampleRequest: (submitSampleObj: SubmitSample) => Promise<void>;
  resetSampleRequest: (resetSampleObj: SubmitSample) => Promise<void>;
  getStandardVerificationUnits: (learningProviderId: string, qualificationId: string) => Promise<void>;
  raiseAlert: (globalAlertMessage: GlobalAlertMessage) => void;
}


export type OwnProps = RouteComponentProps<{ allocationId: string, display: string }>;

interface Props extends DispatchProps, StateProps, OwnProps { }

interface State {
  isBusy: boolean;
  selectedColumn: string;
  direction: string;
  searchText: string;
  showModal: boolean;
  unitId: string;
  unitName: string;
  showConfirmModal: boolean;
  submissionSuccess: boolean
  submissionError: boolean;
  resetSuccess: boolean;
  resetError: boolean;
  showRecallConfirmModal: boolean;
  showResetConfirmModal: boolean;
  showRecallErrorModal: boolean;
}

export class LinkLearners extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    document.title=pageTitle;
    this.state = {
      isBusy: true,
      selectedColumn: "",
      direction: "",
      searchText: "",
      showModal: false,
      unitId: "",
      unitName: "",
      showConfirmModal: false,
      submissionSuccess: false,
      submissionError: false,
      resetSuccess: false,
      resetError: false,
      showRecallConfirmModal: false,
      showResetConfirmModal: false,
      showRecallErrorModal: false
    };

    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.getIconName = this.getIconName.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onRecall = this.onRecall.bind(this);
    this.onReset = this.onReset.bind(this);
    this.showLearners = this.showLearners.bind(this);
    this.cancelModalHandler = this.cancelModalHandler.bind(this);
    this.cancelConfirmModalHandler = this.cancelConfirmModalHandler.bind(this);
    this.successConfirmModalHandler = this.successConfirmModalHandler.bind(this);
    this.successRecallConfirmModalHandler = this.successRecallConfirmModalHandler.bind(this);
    this.cancelRecallConfirmModalHandler = this.cancelRecallConfirmModalHandler.bind(this);
    this.successResetConfirmModalHandler = this.successResetConfirmModalHandler.bind(this);
    this.cancelResetConfirmModalHandler = this.cancelResetConfirmModalHandler.bind(this);
    this.getUnits = this.getUnits.bind(this);    
  }

  render() {    
    const text = {
      headerTitle: 'Select Learners',
      bodyText: 'Choose learner(s) to link to the selected unit',
      closeButtonSRText: 'Cancel',
      modalSaveButtonText: 'Ok',
      modalCancelButtonText: 'Cancel',
     
    };
    const confirmSubmittext = {
      headerTitle: 'Submission Confirmation',
      bodyText: '',
      closeButtonSRText: 'Cancel',
      modalSaveButtonText: 'Ok',
      modalCancelButtonText: 'Cancel'
    };

    const confirmRecalltext = {
      headerTitle: 'Recall Request Confirmation',
      bodyText: '',
      closeButtonSRText: 'Cancel',
      modalSaveButtonText: 'Ok',
      modalCancelButtonText: 'Cancel'
    };

    const confirmResetText = {
      headerTitle: 'Reset Confirmation',
      bodyText: '',
      closeButtonSRText: 'Cancel',
      modalSaveButtonText: 'Ok',
      modalCancelButtonText: 'Cancel'
    };

    require('./manage-learners-modal.css'); 
    const { isBusy, selectedColumn,  submissionSuccess, submissionError, showRecallErrorModal, resetSuccess, resetError } = this.state;
    
    return (
      <div className="unitselection">
        <div className="pe-template__single">
          <h1 className="pe-page-title">{pageTitle}</h1>
        </div>

        <div className="pe-template__single content">
          <section>
            <p className="pe-strategy__centered--small-gap">
              Manage which learner(s) are linked to the selected unit(s).              
            </p>
            {!isBusy && <div>
              <div className="pe-row">
                <div className="pe-col-6">
                  <div className="pe-label small-gap">
                    <span className="pe-label pe-label--bold">
                      Learning Provider
                    </span>
                    <br />
                    {this.props.sampleRequest && this.props.sampleRequest.parentLearningProviderCode ? this.props.sampleRequest.parentLearningProviderCode : this.props.sampleRequest.learningProviderCode}&nbsp;{this.props.sampleRequest.learningProviderName}&nbsp;
                    <b>{this.props.sampleRequest.consortiaId ? '(C)': '' }</b>
                  </div>
                </div>
                <div className="pe-col-6">
                  <div className="pe-label small-gap">
                    <span className="pe-label pe-label--bold">
                      Qualification
                    </span>
                    <br />
                    {this.props.sampleRequest.qualificationGroupCode}&nbsp;{this.props.sampleRequest.qualificationName}&nbsp;
                    <b className="second-sample-text-color">{this.props.sampleRequest.parentRequests && this.props.sampleRequest.parentRequests.length > 0 ? "("+(this.props.sampleRequest.parentRequests.length + 1)+")" : ""}</b>
                  </div>
                </div>
              </div>
              <div className="pe-row">
                <div className="pe-col-6">
                  <div className="pe-label small-gap">
                    <span className="pe-label pe-label--bold">Status</span>
                    <br />
                    {TextHelper.titleCaseUploadStatus(this.props.sampleRequest.status)}
                  </div>
                </div>
                {
                  this.props.sampleRequest.status !== 'CREATE_REQUEST'
                  && this.props.sampleRequest.status !== 'REQUEST_CREATION_IN_PROGRESS' &&
                  <div className="pe-col-6">
                    <div className="pe-label small-gap">
                      <span className="pe-label pe-label--bold">Sample request submitted on</span>

                      <br />


                      <FormattedDate
                        value={this.props.sampleRequest.submissionDate}
                        year="numeric"
                        month="numeric"
                        day="numeric"
                      />&nbsp;
                      
                        <FormattedTime value={this.props.sampleRequest.submissionDate} />
                        
                      {
                        this.props.sampleRequest.submittedBy &&
                        this.props.sampleRequest.submittedBy.length &&
                        <span> by {this.props.sampleRequest.submittedBy}</span>
                      }

                    </div>
                  </div>
                }
              </div>
              <LinkLearnersList
                units={selectedColumn === '' ? this.getUnits() : this.sort(
                  this.getUnits()
                )}
                sampleRequestStatus={this.props.sampleRequest.status}
                onSort={this.handleColumnSort}
                getIconName={this.getIconName}
                goBack={this.goBack}
                onSubmit={this.onSubmit} 
                onRecall={this.onRecall}
                onReset={this.onReset}
                isDisabled={this.disableSubmitButton()}
                showLearners={this.showLearners}
                showReset={!this.showReset()}
                isTeamMember = {this.isTeamMember()}
              />
            </div>
            }

            <PearsonElementsSdk.Modal
              isShown={this.state.showModal}
              text={text}
              footerVisible={false}
              cancelBtnHandler={this.cancelModalHandler}
              successBtnHandler={this.successModalHandler}>
              <LearnerSelection {...this.props as any} singleSampleStandardsVerificationRequest={this.props.sampleRequest} unitId={this.state.unitId} unitName={this.state.unitName} closeEvnt={this.cancelModalHandler} />
            </PearsonElementsSdk.Modal>

            <PearsonElementsSdk.Modal isShown={this.state.showConfirmModal}
              text={confirmSubmittext}
              footerVisible={false}
              cancelBtnHandler={this.cancelConfirmModalHandler}
              successBtnHandler={this.successConfirmModalHandler}>
              <ConfirmationModal cancelBtnHandler={this.cancelConfirmModalHandler}
                successBtnHandler={this.successConfirmModalHandler} />
            </PearsonElementsSdk.Modal>

            {/* RECALL CONFIRMATION MODAL */}
            <PearsonElementsSdk.Modal isShown={this.state.showRecallConfirmModal}
              text={confirmRecalltext}
              footerVisible={false}
              cancelBtnHandler={this.cancelRecallConfirmModalHandler}
              successBtnHandler={this.successRecallConfirmModalHandler}>
              <RecallConfirmationModal cancelBtnHandler={this.cancelRecallConfirmModalHandler}
                successBtnHandler={this.successRecallConfirmModalHandler} />
            </PearsonElementsSdk.Modal>

            {/* RESET CONFIRMATION MODAL */}
            <PearsonElementsSdk.Modal isShown={this.state.showResetConfirmModal}
              text={confirmResetText}
              footerVisible={false}
              cancelBtnHandler={this.cancelResetConfirmModalHandler}
              successBtnHandler={this.successResetConfirmModalHandler}>
              <ResetConfirmationModal cancelBtnHandler={this.cancelResetConfirmModalHandler}
                successBtnHandler={this.successResetConfirmModalHandler} />
            </PearsonElementsSdk.Modal>

           
            <SuccessAlert
              showAlert={submissionSuccess}
              alertTitle="The sample request has been successfully submitted."
              onClose={this.onSubmissionSuccessAlertClose}>
              <span>&nbsp;</span>
            </SuccessAlert>

           
            <SuccessAlert
              showAlert={resetSuccess}
              alertTitle="The sample request has been successfully reset."
              onClose={this.onResetSuccessAlertClose}>
              <span>&nbsp;</span>
            </SuccessAlert>

            
            <ErrorAlert
              showAlert={submissionError}
              alertTitle="The sample request has not been submitted."
              onClose={this.onSubmissionErrorAlertClose}>
                <span>Please try again in a few minutes. If this problem persists contact the&nbsp;
                  <a href="https://qualifications.pearson.com/en/contact-us/pearson-associate.html" target="_blank">Associate Helpdesk</a>
                  </span>
            </ErrorAlert>
            <ErrorAlert
              showAlert={showRecallErrorModal}
              alertTitle="The sample cannot be recalled yet. Please try again later."
              onClose={this.onSubmissionRecallErrorClose}>
                <span>&nbsp;</span>
            </ErrorAlert>

           
            <ErrorAlert
              showAlert={resetError}
              alertTitle="The sample request has not been reset."
              onClose={this.onResetErrorAlertClose}>
                <span>Please try again in a few minutes. If this problem persists contact the&nbsp;
                  <a href="https://qualifications.pearson.com/en/contact-us/pearson-associate.html" target="_blank">Associate Helpdesk</a>
                  </span>
            </ErrorAlert>

            {isBusy && "Loading ..."}
          </section>
        </div>
      </div>
    );    
  }

  
  showSuccess = () => this.setState({ submissionSuccess: true });
  showError = () => this.setState({ submissionError: true });
  showResetSuccess = () => this.setState({ resetSuccess: true });
  showResetError = () => this.setState({ resetError: true });
  onSubmissionSuccessAlertClose = () =>  this.setState({ submissionSuccess: false });
  onSubmissionErrorAlertClose = () => this.setState({ submissionError: false });  
  onResetSuccessAlertClose = () => this.setState({ resetSuccess: false });
  onResetErrorAlertClose = () => this.setState({ resetError: false });
  
  onSubmissionRecallErrorClose = () => {
    setTimeout(()=>this.setState({ showRecallErrorModal : false}),0) 
  }

  componentDidMount() {
    // Get data to load into page from qualification id passed in as part of the route
    // This call is needed since link learners page can be directly reached
    // // from Sample SV Requests page if status is [submitted]
    
    if (this.props.sampleRequest) {
      this.setState({ isBusy: true });
      this.props.getStandardVerificationUnits(
        this.props.sampleRequest.learningProviderId,
        this.props.sampleRequest.qualificationId
      ).then(() => this.setState({ isBusy: false }));
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.sampleRequest && this.props.sampleRequest) {
      this.setState({ isBusy: true });
      this.props.getStandardVerificationUnits(
        this.props.sampleRequest.learningProviderId,
        this.props.sampleRequest.qualificationId
      ).then(() => this.setState({ isBusy: false }));
    }
  }

  getUnits(): UnitWithLearners[] {
    return this.props.sampleRequest.selectedUnits.map((selectedUnit: SelectedUnit) => {
      const unit = _.find(this.props.SVUnitDetails, (qu) => qu.unitId + '' === selectedUnit.unitId);
      return Object.assign({}, unit, selectedUnit);

    }) as UnitWithLearners[];

  }

  cancelModalHandler() {
    this.setState({ showModal: false });
  }

  cancelConfirmModalHandler() {
    this.setState({ showConfirmModal: false });
  }

  successModalHandler() {
    this.setState({ showModal: false });
    // toastr.success('Success!!');
  }

  successConfirmModalHandler() {
    const submitSampleObj = Object.assign({
      assessmentAssociateId: this.props.sampleRequest.assessmentAssociateId,
      standardsVerificationSampleId: this.props.sampleRequest.standardsVerificationSampleId,
      sampleSubmittedBy: `${this.props.assessmentAssociate.firstName} ${this.props.assessmentAssociate.surname}`
    });

    this.props.submitSampleToLearningProvider(submitSampleObj).then(() => {
      this.showSuccess();
    }).catch(() => {
      this.showError();
    });
    this.setState({ showConfirmModal: false });

  }

  successRecallConfirmModalHandler(){
    /** Check if SampleRequest medisetid is not null */
   
    // if(this.props.sampleRequest.hasOwnProperty('mediaSetId') && !this.props.sampleRequest.mediaSetId){
    //   this.setState({ showRecallConfirmModal: false , showRecallErrorModal : true});
         
    // } else {
      const recallSampleObj = Object.assign({
        assessmentAssociateId: this.props.sampleRequest.assessmentAssociateId,
        standardsVerificationSampleId: this.props.sampleRequest.standardsVerificationSampleId,
      });
  
      this.props.recallSampleRequest(recallSampleObj)
      .then(() => {
        // this.showSuccess();
        this.setState({ showRecallConfirmModal: false });
        setTimeout(() => {
          this.props.history.push('/sample-requests');
        }, 0);
      }).catch(() => {
        this.setState({ showRecallConfirmModal: false , showRecallErrorModal : true});
        //this.showError();
      });
    // }
  }

  successResetConfirmModalHandler(){
    /** Check if SampleRequest submittedLearnerUnits is not null */
   
    if(this.props.sampleRequest.hasOwnProperty('submittedUnits') && !this.props.sampleRequest.submittedUnits){
      this.setState({ showResetConfirmModal: false });
    } else {
      const resetSampleObj = Object.assign({
        assessmentAssociateId: this.props.sampleRequest.assessmentAssociateId,
        standardsVerificationSampleId: this.props.sampleRequest.standardsVerificationSampleId,
      }); 
      this.props.resetSampleRequest(resetSampleObj)
      .then(() => {
        this.showResetSuccess();
      }).catch(() => {
        this.showResetError();
      });
      this.setState({ showResetConfirmModal: false });
    }
        
  }

  cancelRecallConfirmModalHandler(){
    this.setState({ showRecallConfirmModal: false });
  }

  cancelResetConfirmModalHandler(){
    this.setState({ showResetConfirmModal: false });
  }

  showLearners(unitId: string, unitName: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    this.setState({ showModal: true, unitId, unitName });
  }

  goBack(event: Event) {
    event.preventDefault();
    if ( (this.props.sampleRequest.status === 'REQUEST_COMPLETED' && this.props.sampleRequest.previousState === 'REQUEST_SUBMITTED') || this.props.sampleRequest.status === 'REQUEST_SUBMITTED' || this.props.sampleRequest.status === 'RECALL_PENDING') {
      this.props.history.push(`/sample-requests/`);
    } else {
      this.props.history.push(`/sample-requests/unit-selection/${this.props.sampleRequest.allocationId}`);
    }
  }

  onSubmit = (event: Event) => {
    event.preventDefault();
    // should submit at some point??
    this.setState({ showConfirmModal: true });
  }

  onRecall = (event: Event) => {
    event.preventDefault();
    // should submit at some point??
    this.setState({ showRecallConfirmModal: true });
  }

  onReset = (event: Event) => {
    event.preventDefault();
    // should submit at some point??
    this.setState({ showResetConfirmModal: true });
  }

  onFieldChange(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault();
    const element = event.target as HTMLInputElement;
    return this.setState({ searchText: element.value.toLowerCase() });
  }

  getIconName = (selectedColumn: string) => {
    if (this.state.selectedColumn === selectedColumn) {
      return this.state.direction === "up" ? "sort-up-18" : "sort-down-18";
    }

    return "sortable-18";
  };

  handleColumnSort = (selectedColumn: string) => {
    return () => {
      let direction = "down";

      if (this.state.selectedColumn === selectedColumn) {
        direction = this.state.direction === "up" ? "down" : "up";
      }

      this.setState({ selectedColumn, direction });
    };
  };

  filter(StandardsVerificationUnits: Unit[]): Unit[] {
    const searchTerm = this.state.searchText;
    if (searchTerm !== "" && searchTerm.length >= 3) {
      const SVUnits = JSON.parse(JSON.stringify(StandardsVerificationUnits));
      const columnNames = ["name", "mandatory"];
      return _.filter(SVUnits, SVUnit => {
        const v2 = _(SVUnit)
          .pick(columnNames)
          .some(searchstring => {
            return _(searchstring)
              .toLower()
              .includes(searchTerm);
          });
        return v2;
      }) as Unit[];
    }
    return StandardsVerificationUnits;
  }

  sort(StandardsVerificationUnits: UnitWithLearners[]): UnitWithLearners[] {
    const lodashDirection = this.state.direction === "down" ? "desc" : "asc";

    const sortedSVUnits = _.orderBy(
      StandardsVerificationUnits,
      [
        SVUnit => {
          if (
            typeof SVUnit[this.state.selectedColumn] === "function"
          ) {
            return SVUnit[this.state.selectedColumn].toLowerCase();
          } else {
            return SVUnit[this.state.selectedColumn];
          }
        }
      ],
      [lodashDirection]
    ) as UnitWithLearners[];
    return sortedSVUnits;
  }

  disableSubmitButton(): boolean {

    const ret = false;
    // code here to check for linked learners??
    const learners = _.find(this.props.sampleRequest.selectedUnits, (u: SelectedUnit) =>
      (u.selectedLearners.length === 0 || this.props.sampleRequest.status === 'REQUEST_SUBMITTED' || this.props.sampleRequest.status === 'RECALL_PENDING'));

    if (learners) {
      return true;
    }
    return ret;
  } 
  
  extractUnits = (src:SelectedUnit[]) => (src) ? src.map((u:SelectedUnit) => u.unitId ) : []

  extractLearners = (src:SelectedUnit[]) => {
    const prevSelectedLearners: string[] = [];   
    if (src){
      src.forEach(u=>{
        const propertyName= (u &&  u.hasOwnProperty('submittedLearners') && u.submittedLearners)? 
        'submittedLearners' : 'selectedLearners';
        u[propertyName].forEach( (l:SelectedLearner) =>{
          if(! _.includes(prevSelectedLearners,l.learnerId)) { 
            prevSelectedLearners.push(l.learnerId);
          }
        })     
      })
    }
    return prevSelectedLearners;
  }

  compareLists = (prev:string[], curr:string[]) => _.isEqual(prev, curr);

  showReset = () =>{
    const prevSelectedUnits = _.sortBy( this.extractUnits(this.props.sampleRequest.selectedUnits), o=>o);
    const currSelectedUnits = _.sortBy( this.extractUnits(this.props.sampleRequest.submittedUnits), o=>o);
    
    const prevSelectedLearners = _.sortBy( this.extractLearners(this.props.sampleRequest.selectedUnits), o=>o);
    const currSelectedLearners = _.sortBy( this.extractLearners(this.props.sampleRequest.submittedUnits), o=>o);

    return ( this.compareLists(prevSelectedUnits, currSelectedUnits) && this.compareLists(prevSelectedLearners, currSelectedLearners) ); 
  }

  isTeamMember():boolean {    
    let isTeamMember = false;
    const proxyAATeamMember = localStorage.getItem('isTeamMember')
    if(proxyAATeamMember === 'true'){
      isTeamMember =true;
    }
    return isTeamMember;
  }
}

export default LinkLearners;