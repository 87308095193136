import * as types from "../../../../actions/action-types";
import { SVUnitDetailsAction } from "./unit-selection-list.actions";
import Unit from "../../../../models/unit";

import initialState from "../../../../reducers/initialState";
import * as _ from "lodash";

export function SVUnitSelectionReducer(
  state: Unit[] = initialState.SVUnitSelectionList,
  // state: Unit[]= [],
  action: SVUnitDetailsAction
): Unit[] {
  if (action.type === types.GET_STANDARDS_VERIFICATION_UNITS_SUCCESS) {
     return action.SVUnitDetails;
  }
    else{ return state;}
     
  
}
