import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as _ from "lodash";

import {
  LearnerSelection,
  StateProps,
  DispatchProps,
  OwnProps
} from "./learner-selection-list.component";

import {
  getStandardVerificationLearners,
  getStandardVerificationLearnersByconsortiaId,
  addLearnerInfoInput,
  removeLearnerInfoInput,
  SVLearnerDetailsAction
} from "./ducks/learner-selection-list.actions";
import { StoreState } from "../../../store/store-state";
import Learners from "../../../models/learner";

import { SampleRequest } from "../../../models/sample-request";

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  const sampleRequest = _.find(state.sampleRequests, {
    allocationId: ownProps.match.params.allocationId
  }) as SampleRequest;

  const SVLearnerList = _.orderBy(
    state.SVLearnerSelectionList,
    (o: Learners) => o.learningProviderId,
    "asc"
  );

  const readOnly = (sampleRequest.status === "REQUEST_SUBMITTED" || sampleRequest.status === "RECALL_PENDING");
  
  return {
    sampleRequest,
    SVLearnerDetails: SVLearnerList,
    readOnly
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, void, SVLearnerDetailsAction>
): DispatchProps => ({
  getStandardVerificationLearners: (learningProviderId, qualificationId, unitId,academicYear) =>
    dispatch(
      getStandardVerificationLearners(learningProviderId, qualificationId, unitId,academicYear)
    ),
  addLearnerInfoInput: newLearnerInput =>
    dispatch(addLearnerInfoInput(newLearnerInput)),
  removeLearnerInfoInput: newLearnerInput =>
    dispatch(removeLearnerInfoInput(newLearnerInput)),
  getStandardVerificationLearnersByconsortiaId:(consortiaId,qualificationId,academicYear) =>
    dispatch(
      getStandardVerificationLearnersByconsortiaId(consortiaId, qualificationId,academicYear)
    ),
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(LearnerSelection);
