import * as React from 'react';
// import { FormattedDate } from 'react-intl';
const PearsonElementsSdk = require('@pearson-components/modal');

import { MediaRequestFile } from '../../../models/media-request';
import { LearnerUnit } from '../../../models/LearnerUnit';
import LearnerMediaList from './learner-media-list';

interface ViewLearnerMaterialModalProps {
  showModal: boolean;
  cancelModalHandler: () => void;
  successModalHandler: () => void;
  filterFilesForLearner: (
    mediaRequestFiles: MediaRequestFile[],
    learnerUnit: LearnerUnit
  ) => MediaRequestFile[];
  learnerUnit: LearnerUnit;
  files: MediaRequestFile[];
  qualification: string,
  learningProvideId: string;
  mediaSetId: string;
  parentRequests: string[];
}

const ViewLearnerMaterialModal = ({
  showModal,
  cancelModalHandler,
  successModalHandler,
  filterFilesForLearner,
  learnerUnit,
  files,
  qualification,
  learningProvideId,
  mediaSetId,
  parentRequests

}: ViewLearnerMaterialModalProps) => {
  const text = {
    headerTitle: 'View Learner Material',
    bodyText: '',
    closeButtonSRText: 'Cancel',
    modalSaveButtonText: 'Ok',
    modalCancelButtonText: 'Cancel'
  };
  require('./view-learner-material-modal.css');
  const guidance = '';//'Select the appropriate action to access any uploaded material';
  return (
    <PearsonElementsSdk.Modal
      isShown={showModal}
      text={text}
      footerVisible={false}
      cancelBtnHandler={cancelModalHandler}
      successBtnHandler={successModalHandler} 
      postTitleText = {guidance} 
      postTitleTextClass = 'pe-label'     
    >
      {learnerUnit !== null && (
        <LearnerMediaList
          files={filterFilesForLearner(JSON.parse(JSON.stringify(files)), learnerUnit)}
          learnerUnit={learnerUnit} 
          qualification={qualification} 
          learningProviderId = {learningProvideId} 
          mediaSetId = {mediaSetId}  
          parentRequests = {parentRequests}      
        />
      )}
    </PearsonElementsSdk.Modal>
  );
};

export default ViewLearnerMaterialModal;
