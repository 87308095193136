import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as _ from "lodash";

import { 
  SearchModal, 
  StateProps,
  DispatchProps,
  OwnProps
} from "./search-modal.component";

// import {
//   getStandardVerificationLearners,
//   addLearnerInfoInput,
//   removeLearnerInfoInput,
//   getProxySampleRequests,
//   SearchModalAction
// } from "./ducks/search-modal.actions";

import {
  getSampleStandardsVerificationRequests,
  SampleStandardsVerificationRequestsAction
} from '../../shared/sample-standards-verification-request.actions';

import { StoreState } from "../../../store/store-state";
// import Learners from "../../../models/learner";

// import { SampleRequest } from "../../../models/sample-request";

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  // const sampleRequest = _.find(state.sampleRequests, {
  //   allocationId: ownProps.match.params.allocationId
  // }) as SampleRequest;

  // const SVLearnerList = _.orderBy(
  //   state.SVLearnerSelectionList,
  //   (o: Learners) => o.learningProviderId,
  //   "asc"
  // );

  // const readOnly = (sampleRequest.status === "REQUEST_SUBMITTED" || sampleRequest.status === "RECALL_PENDING");

  return { 
    sampleRequestCount: state.sampleRequests.length? state.sampleRequests.length: -1,  
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, void, SampleStandardsVerificationRequestsAction>
): DispatchProps => ({
  getProxySampleRequests: assessmentAssociateId =>
    dispatch(getSampleStandardsVerificationRequests(assessmentAssociateId))
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(SearchModal);
