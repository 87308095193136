import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as _ from "lodash";

import {
  UnitSelection,
  StateProps,
  DispatchProps,
  OwnProps
} from "./unit-selection-list.component";
 
import {
  getStandardVerificationUnits,
  modifySelectedStandardsVerificationUnits,
  SVUnitDetailsAction,
  ondeActivateRequest,
  onreActivateRequest,
  // resetStandardVerificationUnits
} from "./ducks/unit-selection-list.actions";
import { StoreState } from "../../../store/store-state";
import Unit from "../../../models/unit";

import { SampleRequest } from "../../../models/sample-request";


const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  const sampleRequest = _.find(state.sampleRequests, {
    allocationId: ownProps.match.params.allocationId
  }) as SampleRequest; 
  

  
  const SVUnitList = _.orderBy(
    state.SVUnitSelectionList,
    (o: Unit) => o.name,
    "asc"
  );
  return {
    sampleRequest,
    SVUnitDetails: SVUnitList,
        
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
  StoreState,
  void,
  SVUnitDetailsAction
>
): DispatchProps => ({
  getStandardVerificationUnits: (learningProviderId, qualificationId) =>
    dispatch(getStandardVerificationUnits(learningProviderId, qualificationId)),
  ondeActivateRequest : (standardsVerificationSampleId,assessmentAssociateId)=> 
    dispatch(ondeActivateRequest(standardsVerificationSampleId,assessmentAssociateId)),
  onreactivateRequest : (standardsVerificationSampleId: string,assessmentAssociateId: string)=>
     dispatch(onreActivateRequest(standardsVerificationSampleId,assessmentAssociateId)),
    modifySelectedStandardsVerificationUnits: (
    unitDetails,
    unitIds,
    action,
    standardsVerificationSampleId,
    assessmentAssociateId
  ) =>
    dispatch(
      modifySelectedStandardsVerificationUnits(
        unitDetails,
        unitIds,
        action,
        standardsVerificationSampleId,
        assessmentAssociateId
      ))
    // ),
    // modifySelectedStandardsVerificationUnitsSuccessAction:(selectedUnits: UnitSelected) =>{
    //   dispatch(modifySelectedStandardsVerificationUnitsSuccessAction(selectedUnits))
    // }
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(UnitSelection);
