export default class Learner {    
    learnerId: string; 
    learningProviderId: string; 
    qualificationId: string; 
    code: string; 
    firstName: string; 
    surname: string; 
    dateOfBirth: Date = new Date();
    gender:  number=0;
    genderId:  number=0;
    learningProviderCode: string;
    learningProviderName: string;
}

export class SelectedLearner {
    learnerId: string;
}