import * as React from 'react';
import { MediaRequestFile } from '../../../models/media-request';
import { LearnerUnit } from '../../../models/LearnerUnit';

interface AccessLearnerMaterialListRowProps {
    sampleMediaRequestLearner: LearnerUnit;
    mediaRequestFiles: MediaRequestFile[];
    showFiles: (
      learnerUnit: LearnerUnit,
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => void;
    isConsortia: boolean;
    learningProviderCode:string;
    learningProviderName :string;
}

const AccessLearnerMaterialListRow = ({
    sampleMediaRequestLearner,
    mediaRequestFiles,
    showFiles,
    isConsortia,
    learningProviderCode,
    learningProviderName 
  }: AccessLearnerMaterialListRowProps) => {
    return (
        <tr>
            {isConsortia &&(<td>{learningProviderCode}</td>)}
            {isConsortia &&(<td>{learningProviderName}</td>)}
            <td>
                {sampleMediaRequestLearner.learnerCode}
            </td>
            <td>
                
                {sampleMediaRequestLearner.firstName +
                ' ' +
                sampleMediaRequestLearner.surname}
            </td>

            <td>
                {/* {sampleMediaRequestLearner.unitId}&nbsp; */}
                {sampleMediaRequestLearner.unitName}
            </td>
            <td>
                {mediaRequestFiles.length > 0 && (
                <a
                    href="#"
                    onClick={showFiles.bind(event, sampleMediaRequestLearner)}
                >
                    View {mediaRequestFiles.length} file(s)
                </a>
                )}
                {mediaRequestFiles.length === 0 && '0 file(s) uploaded' }
            </td>
        </tr>
    );
  }

export default AccessLearnerMaterialListRow;