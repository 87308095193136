import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { userTypeEnums} from '../../features/shared/enums'
import DropdownMenu from '../../features/shared/dropdown-menu';

require('./nav.scss');

interface AATeamsList {
  teamName: string, 
}

interface NavProps {
  loading: boolean;
  isAuthenticated: boolean;
  userType: string;
  isAATeamLead: boolean;
  proxyUserName: string;
  showCreateMenu: boolean;
  aaTeams: AATeamsList[];
  proxyAATeamMemberName: string;  
  onAATeamSelect: (
    selectedAATeamName: string, 
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void
}

export const Nav = ({ 
  loading, 
  isAuthenticated, 
  userType, 
  proxyUserName, 
  showCreateMenu, 
  isAATeamLead, 
  proxyAATeamMemberName,
  aaTeams,
  onAATeamSelect
}: NavProps) => (
  <div className="barStyleTabs">
    <ul className="tabs__labels" role="tablist">
      <li role="presentation">
        <NavLink
          to="/"
          exact={true}
          className="pe-label light"
          activeClassName="activeTab"
        >
          Home
        </NavLink>
      </li>            
      {isAuthenticated && userType && userType.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase() &&
      <li role="presentation">        
        <NavLink
          to="/select-an-aa"
          className="pe-label light"
          activeClassName="activeTab"
        >
          Select an AA
        </NavLink>
      </li>}
        {/* Link is not accessible to users */}
      {/* {isAuthenticated && showCreateMenu &&
        <li role="presentation">
          <NavLink
            to="/create-sample-request"
            className="pe-label light"
            activeClassName="activeTab"
          >
            Create Sample Request
          </NavLink>
        </li>} */}
        {isAuthenticated &&
        <li role="presentation">
          <NavLink
            to="/sample-requests"
            className="pe-label light"
            activeClassName="activeTab"
          >
            Sample Requests
          </NavLink>
        </li>} 
    </ul>
    {isAuthenticated && userType && userType.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase() && 
      <span className="pull-right barStyleText">{proxyUserName.length && proxyUserName!=='undefined'? proxyUserName: ''}</span>
    }
    {isAuthenticated &&  isAATeamLead && 
      <span className="pull-right">
        <DropdownMenu 
        render={menuItems(aaTeams, onAATeamSelect)}
        selectedOptionText={proxyAATeamMemberName}
        />
      </span>
    }
  </div>
);

const menuItems = ( 
  aaTeams: AATeamsList[], 
  clickHandler:(
    teamName: string, 
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  )=>void 
) =>
   () =>(
    
    <ul>
      <li>
        <a onClick={clickHandler.bind(event, '')}>My Requests</a>
      </li>

      {/* <li>
        <a onClick={clickHandler.bind(event, '')}>His Requests</a>
      </li>

      <li>
        <a onClick={clickHandler.bind(event, '')}>Her Requests</a>
      </li>

      <li>
        <a onClick={clickHandler.bind(event, '')}>Their Requests</a>
      </li>

      <li>
        <a onClick={clickHandler.bind(event, '')}>No Requests</a>
      </li>

      <li>
        <a onClick={clickHandler.bind(event, '')}>This text is long, itwrapsbutnotasong</a>
      </li> 

      <li>
        <a onClick={clickHandler.bind(event, '')}>This text is long, it wraps but not a song</a>
      </li>        */}

      { (aaTeams && aaTeams.length)?  
        aaTeams.map( (t,i)=>(
          <li key={i}>
            <a onClick={clickHandler.bind(event,t.teamName)}>{t.teamName}</a>
          </li>
        ))      
       :null
      }     
     </ul>
  )
    

export default Nav;