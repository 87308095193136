import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import * as _ from "lodash";
import {ProxyAATeamMember} from '../../models/team';
import TeamMemberList from './team-member-list';
// @ts-ignore
import { history } from "../router";

export interface StateProps {
  // sampleMediaRequests: MediaRequest[] | null;
  // learningProviderId:string;
  selectedAATeamName: string;
  selectedAATeam: any;
  proxyAATeamMember?: ProxyAATeamMember;
 
}

export interface DispatchProps {
  loadSampleStandardsVerificationRequests?: (assessmentAssociateId: string) => Promise<void>;
  updateProxyAATeamMember: (proxyAATeamMember: ProxyAATeamMember) => void;
}

export type OwnProps = RouteComponentProps<{teamName:string}>;

interface Props extends DispatchProps, StateProps, OwnProps {
  // sampleMediaRequests:MediaRequest[] ;
  // cancelBtnHandler: any;
  // onFieldChange: (event: React.FormEvent<HTMLInputElement>) => void;
  // errors: any;
  closeEvent: any;
}

interface State {
   learningProviderCode:string;
   selectedColumn: string;
   direction: string;
}

export class SelectTeamMember extends React.Component<Props, State> {
  onSort: void;

  constructor(props: Props) {
    
    super(props);
    this.state = {
      learningProviderCode:'', 
      selectedColumn: "",
      direction: "", 
    }
   
    this.onSearchClickHandler =  this.onSearchClickHandler.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSort = this.handleColumnSort.bind(this);
    this.getIconName = this.getIconName.bind(this);
    
  }
  componentDidMount() {
    // Get data to load into page from qualification id passed in as part of the route   
    this.handleColumnSort('name');
    
  }
  componentDidUpdate(prevProps: Props) {
    this.handleColumnSort('name');
  }

  render() {
    const {  selectedColumn } = this.state;    
    require('./team-member.css'); 
    const teamMemberList = this.props.selectedAATeam && this.props.selectedAATeam.teamMembers;
    return (
      <div>
        <div className="pe-template__single">
          <p style={{fontSize: "17px"}}>{this.props.selectedAATeamName}</p>          
        </div>
        <div className="pe-template__single">
        Select a team member to view their requests.  
        </div>
        <div className="pe-template__single content">
        
        <TeamMemberList onSelect={this.onSelect}  teamMemberList={
                    selectedColumn === ""
                      ? teamMemberList 
                      : this.sort(teamMemberList)
                  }  onSort={this.handleColumnSort} getIconName={this.getIconName} closeEvent={this.closeEvent} />
        
        </div>
      </div>

    );
  }

  handleColumnSort = (selectedColumn: string) => {
    return () => {
      let direction = "up";

      if (this.state.selectedColumn === selectedColumn) {
        direction = this.state.direction === "up" ? "down" : "up";
      }

      this.setState({ selectedColumn, direction });
    };
  };

  getIconName = (selectedColumn: string) => {
    if (this.state.selectedColumn === selectedColumn) {
      return this.state.direction === "up" ? "sort-up-18" : "sort-down-18";
    }

    return "sortable-18";
  };

  closeEvent = (e: Event) => {
    
    
    
    this.props.closeEvent();
  };

  sort(teamMembers: any) {
    const lodashDirection = this.state.direction === "down" ? "desc" : "asc";

    const sortedTeamMembers = _.orderBy(
      teamMembers,
      [
        teamMember => {
          if (teamMember &&
            teamMember[this.state.selectedColumn] && 
            typeof teamMember[this.state.selectedColumn].toLowerCase === "string"
          ) {
            return teamMember[this.state.selectedColumn].toLowerCase;
          } else {
            return teamMember[this.state.selectedColumn];
          }
        }
      ],
      [lodashDirection]
    ) ;
    return sortedTeamMembers;
  }

  onSelect(assessmentAssociateId: string,AATeamMember:any,event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
     event.preventDefault();    
     const AATeamMemberData = Object.assign({
      teamName:this.props.selectedAATeamName,
      firstName: AATeamMember.firstName,
      lastName: AATeamMember.lastName,
      assessmentAssociateId: AATeamMember.assessmentAssociateId,
      aaNumber:AATeamMember.aaNumber,
    });

    
    this.props.updateProxyAATeamMember(AATeamMemberData);
    localStorage.setItem('isTeamMember','true');
    localStorage.setItem('isTeamMemberaa',AATeamMember.aaNumber);
    localStorage.setItem('isTeamMembername',AATeamMember.firstName+' '+AATeamMember.lastName);
    this.props.loadSampleStandardsVerificationRequests!(AATeamMember.aaNumber);
    this.props.closeEvent();
    history.push('/sample-requests');  

   }
  
  onSearchClickHandler(event:Event){
    event.preventDefault();
    
    //  const prevProxyId = localStorage.getItem('prevProxyId');
       
    // this.props.getMediaSetsByLearningProviderCode(this.state.learningProviderCode).then((response ) => {
    //   if(this.props.sampleMediaRequests.length >0){
    //     this.props.cancelBtnHandler();
    //     this.props.history.push(`/sample-requests/`);
    //   }else{
    //     const validations = Object.assign({}, this.state.validations, this.validateInputElement('learningProviderCode', "empty"));
    //     (prevProxyId === null)?this.props.getSampleMediaRequests(this.props.learningProviderId):this.props.getMediaSetsByLearningProviderCode(prevProxyId+'');
    //     return this.setState({validations });
    //   }
    // }).catch(() => {
    //   console.log("")
    // });
  }
  onFieldChange(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault();

    // const element = event.target as HTMLInputElement;    
    // const validations = Object.assign({}, this.state.validations, this.validateInputElement(element.id, element.value));

    // return this.setState({ learningProviderCode:element.value,validations });
  }
}
export default SelectTeamMember;
