import * as React from "react";
import { useState } from "react";

import { LearnerUnit } from "../../../models/LearnerUnit";
import { MediaRequestFile } from "../../../models/media-request";
import { FormattedDate, FormattedTime } from "react-intl";
import AuthForgeRock from '../../../actions/auth/auth';
const auth = new AuthForgeRock();
import axios from "axios";
import config from "../../../app-config";
import TextHelper from "../../shared/text-formatting";
import moment from "moment";
import _ from "lodash";
import groupImage from "./group-18.svg";
import {Button,Table} from 'ukas.uux.react-components';
// const PearsonElementsSDK = require("@pearson-components/elements-sdk/build/dist.elements-sdk");

interface LearnerMediaListProps {
  files: MediaRequestFile[];
  learnerUnit: LearnerUnit;
  qualification: string;
  learningProviderId: string;
  mediaSetId: string;  
  parentRequests: string[];
}

const LearnerMediaList = ({
  files,
  learnerUnit,
  qualification,
  learningProviderId,
  mediaSetId,
  parentRequests
 
}: LearnerMediaListProps) => {
  const [selectedColumn] = useState("fileName");
  const [direction] = useState("up");
  const [searchText] = useState("");

  files = selectedColumn==""? filter(files, searchText) : sort(files, selectedColumn, direction)

  return (
    <div>
      <p className="pe-strategy__centered--small-gap">
        Select the appropriate action to access any uploaded material
      </p>
      <div>
        <span className="pe-label pe-bold">Learner:</span>&nbsp;
        <span className="pe-label">
          {learnerUnit.learnerId}&nbsp;{learnerUnit.firstName}&nbsp;
          {learnerUnit.surname}
        </span>
        <br />
        <span className="pe-label pe-bold">Qualification:</span>&nbsp;
        <span className="pe-label">{qualification}</span>&nbsp;
        <b className="second-sample-text-color">{parentRequests && parentRequests.length > 0 ? "("+(parentRequests.length + 1)+")" : ""}</b>
        <br />
        <span className="pe-label pe-bold">Unit:</span>&nbsp;
        <span className="pe-label">
          {learnerUnit.unitName}
        </span>
      </div>
      <br />
      <div className="scrollTable">
      <Table>
        <thead>
          <tr>
            {/* <th>File name</th>
            <th>Size</th>
            <th>Upload date and time</th> */}
                <td>File name</td>
                <td>Upload details</td>
         
            
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file: MediaRequestFile) => (
            <tr className="downloadables" key={file.fileId}>
              <td>{getDownloadFileName(file.fileName,file.orgFileName)} { (file.learnerIds && file.learnerIds.length >1) && (<div className="groupIcon">
                {/* <PearsonElementsSDK.Icon name="group-18" className="infoFillIcon" /> */}
                <img src={groupImage}/>
                        &nbsp; </div>) }
              <div style={{ color: "grey", fontSize: "12px" }}>
               {file.fileSize?file.fileSize:''}
                        </div></td>
           
            
              <td>
                <FormattedDate
                  value={file.createdDateTime}
                  year="numeric"
                  month="numeric"
                  day="numeric"
                />
                &nbsp;
                <FormattedTime value={file.createdDateTime} />
              </td>
              <td>
                <Button
                  variant="primary"
                  onClick={getPresignedUrl.bind(event,
                      learningProviderId,
                      mediaSetId,
                      file.fileName,
                      getDownloadFileKey(file.orgFileName,file.fileId,file.transformedFileKey)
                    )}
                >
                  Download
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>
    </div>
  );
};

const getDownloadFileKey = (fileName:string,fileId:string,transferFileKey:string) =>{
  const extension = getExtension(fileName);
  if(config.SourceFileExtension.includes(extension)){
    return fileId;
  }else{
    return transferFileKey
  }
}

const getDownloadFileName = (fileName:string,orgFileName:string) =>{
  const extension = getExtension(orgFileName);
  if(config.SourceFileExtension.includes(extension)){
    return orgFileName;
  }else{
    return fileName
  }
}

const getPresignedUrl = async (
  learningProviderId: string,
  mediaSetId: string,
  fileName: string,
  fileId: string,
  e: React.MouseEvent,
) => {
  e.preventDefault();
  const headers = {"Authorization": `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}`};
  const response = await axios
    .get(
      `${config.API_GATEWAY.URL}/pre-signed-file-url/${learningProviderId}/${mediaSetId}/${encodeURIComponent(
        fileId
      )}`, {headers}
    );
  downloadLearnerMedia(response.data, fileName);
};

const downloadLearnerMedia = (preSigneUrl: string, fileName: string) => {
  const extension = getExtension(preSigneUrl);
  const link = document.createElement("a");
  link.href = preSigneUrl;
  link.setAttribute("download", `${fileName}.${extension}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
  // const xhr = new XMLHttpRequest();
  // xhr.responseType = 'blob';
  // xhr.open('GET', preSigneUrl);
 
  // xhr.onload = () => {
  //   if (xhr.status !== 200) { // analyze HTTP status of the response
  //     console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
  //   } else { // show the result
  //     console.log(`Done, got ${xhr.response.length} bytes`); // responseText is the server
  //     const url = window.URL.createObjectURL(xhr.response);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", `${fileName}.${extension}`);
  //     document.body.appendChild(link); 
  //     link.click();
  //     link.remove();
  //     window.URL.revokeObjectURL(url);
  //   }  
  // };

  // xhr.send();
};

const getExtension = (url: string) => {
 let splitUrl = url.split(/\#|\?/);
 let extension = "";
  if (splitUrl) {
    splitUrl = splitUrl[0].split(".");
    if (splitUrl && splitUrl.length > 0) {
    }
  }
  return extension.trim();
};


const filter = (
  files: MediaRequestFile[],
  searchText: string
): MediaRequestFile[] => {
  const searchTerm = searchText;
  if (searchTerm !== "" && searchTerm.length >= 3) {
    const mediaRequests = JSON.parse(JSON.stringify(files));
    const columnNames = ["fileName", "createdDateTime"];
    return _.filter(mediaRequests, mediaRequest => {
      mediaRequest.status = TextHelper.titleCaseUploadStatus(
        mediaRequest.status
      );
      mediaRequest.deadlineDateTime = moment(
        new Date(mediaRequest.deadlineDateTime)
      ).format("DD MMM YYYY");
      return _(mediaRequest)
        .pick(columnNames)
        .some(searchstring => {
          return _(searchstring)
            .toLower()
            .includes(searchTerm);
        });
    }) as MediaRequestFile[];
  }
  return files;
};

const sort = (
  files: MediaRequestFile[],
  selectedColumn: string,
  direction: string
): MediaRequestFile[] => {
  const lodashDirection = direction === "down" ? "desc" : "asc";
  const sortedSampleMediaRequestFiles = _.orderBy(
    files,
    [
      mediaRequestFile => {
        // Added this condition to counteract page crash if 
        // field has no value
        if( !mediaRequestFile[selectedColumn]){
          return null;
        }
        if (
          typeof mediaRequestFile[selectedColumn].toLowerCase === "function"
        ) {
          return mediaRequestFile[selectedColumn].toLowerCase();
        } else {
          return mediaRequestFile[selectedColumn];
        }
      }
    ],
    [lodashDirection]
  ) as MediaRequestFile[];
  return filter(sortedSampleMediaRequestFiles, '');
};

export default LearnerMediaList;
