import * as React from 'react';

// const PearsonElementsSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');
import { Button, Table, Select } from 'ukas.uux.react-components';
import AccessLearnerMaterialListRow from './access-learner-material-list-row'
import { MediaRequestFile } from '../../../models/media-request';
import { LearnerUnit } from '../../../models/LearnerUnit';
import { statusEnums } from "../../shared/enums";
import TextHelper from "../../shared/text-formatting";

interface AccessLearnersMaterialProps {
  sampleMediaRequestLearners: LearnerUnit[];
  mediaRequestFiles: MediaRequestFile[];
  units: Array<{ unitId: string; unitName: string }>;
  onSelectUnit: (event: any) => void;
  goBack: () => void;
  showFiles: (
    learnerUnit: LearnerUnit,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  unitFilterLabel: string;
  onSort: (item: string) => void;
  getIconName: (columnName: string) => string;
  currentStatus: string;
  onReject: () => void;
  isConsortia: boolean;
  learningProviderCode: string;
  learningProviderName: string;
  isTeamMember: boolean
}
const AccessLearnersMaterialList = ({
  sampleMediaRequestLearners,
  mediaRequestFiles,
  units,
  onSelectUnit,
  goBack,
  showFiles,
  unitFilterLabel,
  onSort,
  getIconName,
  currentStatus,
  onReject,
  isConsortia,
  learningProviderCode,
  learningProviderName,
  isTeamMember
}: AccessLearnersMaterialProps) => {

  currentStatus = currentStatus.includes('_') ? TextHelper.titleCaseUploadStatus(currentStatus) : currentStatus;
  return (
    <div>
      <div className="pe-table-toolbar">
        <div className="pull-left">
          {' '}
          <Select
            title={unitFilterLabel}
            id="unitsFilterButton"
            label="Filter by unit"
            onChange={onSelectUnit}
          >
            <option
               id="0"
              value="0"
              label="All Units"
          
            />
            {units.map(unit => (
              <option
                key={unit.unitId}
                id={unit.unitId}
                value={unit.unitName}
                label={unit.unitId + ' ' + unit.unitName}
              
              />
            ))}
          </Select>
        </div>
        <div className="pull-right">
          <div className="pe-btngroup" />
        </div>
        <div className="clearfix" />
      </div>

      <Table>
        <thead>
          <tr>
            {isConsortia && (<td>Learner Provider Code</td>)}
            {isConsortia && (<td>
              Learner Provider
            </td>
            )}
            <td>Learner Cod </td>
            <td>Learner Name </td>
            <td>Unit</td>
            <td>Action</td>

          </tr>
        </thead>
        <tbody>
          {sampleMediaRequestLearners.map((learner: LearnerUnit, index) => (
            <AccessLearnerMaterialListRow
              key={learner.learnerId + ':' + index}
              sampleMediaRequestLearner={learner}
              mediaRequestFiles={mediaRequestFiles.filter(
                (f: MediaRequestFile) =>
                  (f.learnerIds.filter((l: string) => l === learner.learnerId)
                    .length > 0) && (f.unitId === learner.unitId)
              )}
              learningProviderCode={learningProviderCode}
              learningProviderName={learningProviderName}
              showFiles={showFiles}
              isConsortia={isConsortia}
            />
          ))}
          {sampleMediaRequestLearners.length === 0 &&
            (
              <tr>
                <td>Loading....</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            )}
        </tbody>
      </Table>
      <Button onClick={goBack}>
        Back
      </Button>

      {!(currentStatus === statusEnums.REQUEST_COMPLETED) ?
        <div className="pull-right">
          <Button
            variant="primary"
            onClick={onReject}
            disabled={!(currentStatus === statusEnums.REQUEST_RECEIVED) || isTeamMember}
          >
            Reject Sample
          </Button>
        </div>
        :
        ''}
      <p>&nbsp;</p>
    </div>
  );
}

export default AccessLearnersMaterialList;