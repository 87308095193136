import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import User from '../../models/user';
import AATeam from '../../models/team'
import { AssessmentAssociate } from '../../models/assessment-associate';
// import { permissionGroups } from '../../models/user';
import * as action_types from '../../actions/action-types';
import {
  ajaxCallError,
  beginAjaxCall,
  AjaxCallActions
} from '../../actions/ajax-status.actions';

import config from '../../app-config';
import { StoreState } from '../../store/store-state';
import _ from 'lodash';
import { userTypeEnums } from '../shared/enums';
import Auth from "../../actions/auth/auth";
const auth = new Auth();

interface IsAATeamLeadSuccessAction extends Action {
  type: action_types.IS_AA_TEAM_LEAD_SUCCESS;
  isAATeamLead: boolean;
}

interface SelectAATeamSuccessAction extends Action {
  type: action_types.SELECT_AA_TEAM_SUCCESS;
  selectedAATeamName: string;
}

interface GetUserDetailsSuccessAction extends Action {
  type: action_types.GET_USER_DETAILS_SUCCESS,
  userDetails: User;
}

interface GetAssessmentAssociateDetailsSuccessAction extends Action {
  type: action_types.GET_ASSESSMENTASSOCIATE_DETAILS_SUCCESS,
  assessmentAssociateDetails: AssessmentAssociate;
}

interface GetAATeamLeadDetailsSuccessAction extends Action {
  type: action_types.GET_AA_TEAM_LEAD_DETAILS_SUCCESS;
  aaTeams: AATeam[];
}

interface ValdateUserPermissionsSuccessAction extends Action {
  type: action_types.VALIDATE_USER_PERMISSIONS_SUCCESS,
  userPermissionValidated: boolean;
}

interface GetEnvAction extends Action {
  type: action_types.GET_ENV_SUCCESS,
  e: boolean;
}

export type UserDetailsAction =
  GetUserDetailsSuccessAction
  | GetAssessmentAssociateDetailsSuccessAction
  | ValdateUserPermissionsSuccessAction
  | GetEnvAction
  | SelectAATeamSuccessAction
  | GetAATeamLeadDetailsSuccessAction
  | IsAATeamLeadSuccessAction
  | AjaxCallActions;

type ThunkResult<R> = ThunkAction<
  R,
  StoreState,
  undefined,
  UserDetailsAction
>;

export const getUserDetailsSuccessAction = (
  userDetails: User
): GetUserDetailsSuccessAction => ({
  userDetails,
  type: action_types.GET_USER_DETAILS_SUCCESS
});

export const getUserDetails = (forgeRockId: string): ThunkResult<Promise<void>> => {
  const headers = { Authorization: `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}` };

  return dispatch => {
    dispatch(beginAjaxCall());
    const mockUsers = config.IS_MOCK.MOCK_USERS ? 1 : 0;
    return axios
      .get(`${config.API_GATEWAY.URL}/user/getuser/${forgeRockId}/${mockUsers}`, {headers})
      .then((response) => {
        const userDetails = response.data as User;
        dispatch(getUserDetailsSuccessAction(userDetails));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  }
}


export const getAssessmentAssociateDetailsSuccessAction = (
  assessmentAssociateDetails: AssessmentAssociate
): GetAssessmentAssociateDetailsSuccessAction => ({
  assessmentAssociateDetails,
  type: action_types.GET_ASSESSMENTASSOCIATE_DETAILS_SUCCESS
});

export const getAssessmentAssociateDetails = (forgeRockId: string): ThunkResult<Promise<void>> => {
  const headers = { Authorization: `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}` };

  return dispatch => {
    dispatch(beginAjaxCall());
    // const mockUsers = config.IS_MOCK.MOCK_USERS?1:0;
    return axios
      .get(`${config.API_GATEWAY.URL}/user/getAssessmentAssociate/${forgeRockId}`, {headers})
      .then((response) => {
        const assessmentAssociate = new AssessmentAssociate(response.data.aaNumber, response.data.aaNumber, response.data.userName, response.data.firstName, response.data.lastName)
        dispatch(getAssessmentAssociateDetailsSuccessAction(assessmentAssociate));
        if (response.data.isAATeamLead) {
          const aaTeamsData = response.data.aaTeams;
          dispatch(
            getAATeamLeadDetailsSuccessAction(
              aaTeamsData.map((team: AATeam) => {
                const aaTeams = new AATeam();
                aaTeams.teamName = team.teamName;
                aaTeams.academicYear = team.academicYear;
                aaTeams.teamLeader = team.teamLeader;
                aaTeams.teamMembers = team.teamMembers;
                return aaTeams;
              })
            )
          );
          dispatch(isAATeamLead(true));
        }
        else
        {
          dispatch(isAATeamLead(false));
        }
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  }
}

export const getAATeamLeadDetailsSuccessAction = (
  aaTeams: AATeam[]
): GetAATeamLeadDetailsSuccessAction => ({
  aaTeams,
  type: action_types.GET_AA_TEAM_LEAD_DETAILS_SUCCESS
});

/** Team lead Details includes teams and team members */
/** The assessmentAssociateNumber param is team lead AANumber */
// export const getAATeamLeadDetails = (
//   assessmentAssociateNumber: string,
//   academicYear: string
// ): ThunkResult<Promise<void>> => {
//   return dispatch => {
//     dispatch(beginAjaxCall());

//     /**** MOCK DATA SWITCH */
//     const isMockTeams = '0';

//     return axios
//       .get(`${config.API_GATEWAY.URL}/user/get-team-lead/${assessmentAssociateNumber}/${academicYear}/${isMockTeams}`, {
//         params: {
//           assessmentAssociateNumber,
//           academicYear
//         }
//       })
//       .then(response => {
//         const aaTeamsData = response.data;
//         // console.log("aaTeamsData--", aaTeamsData);
//         if (aaTeamsData.length) {
//           dispatch(
//             getAATeamLeadDetailsSuccessAction(
//               aaTeamsData.map((team: AATeam) => {

//                 const aaTeams = new AATeam();

//                 aaTeams.teamName = team.teamName;
//                 aaTeams.academicYear = team.academicYear;
//                 aaTeams.teamLeader = team.teamLeader;
//                 aaTeams.teamMembers = team.teamMembers;

//                 return aaTeams;
//               })
//             )
//           );
//           dispatch(isAATeamLead(true))
//         } else {
//           dispatch(isAATeamLead(false))
//         }
//       })
//       .catch(error => {
//         dispatch(ajaxCallError(error));
//         throw error;
//       });
//   };
// }



export const validateUserPermissionsAction = (
  userPermissionValidated: boolean
): ValdateUserPermissionsSuccessAction => ({
  userPermissionValidated,
  type: action_types.VALIDATE_USER_PERMISSIONS_SUCCESS
});

export const validateUserPermissions = (
  family_name: string,
  loggedInUserPermissionIds: number[])
  : ThunkResult<Promise<void>> => {

  const isUserExternal = (family_name.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase()) ? '0' : '1';
  const currentUserPermissions = (loggedInUserPermissionIds.length) ? loggedInUserPermissionIds.join('-') : '0-0'
  const headers = { Authorization: `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}` };

  return dispatch => {
    dispatch(beginAjaxCall());

    return axios
      .get(`${config.API_GATEWAY.URL}/permissions/${isUserExternal}/${currentUserPermissions}`, {headers})
      .then((response) => {
        dispatch(validateUserPermissionsAction(response.data.iupv));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  }
}

export const getEnvSuccessAction = (
  e: boolean
): GetEnvAction => ({
  e,
  type: action_types.GET_ENV_SUCCESS
});

export const getEnv = (): ThunkResult<Promise<void>> => {
  const headers = { Authorization: `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}` };

  return dispatch => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/nav`, {headers})
      .then((response) => {
        const envDetails = response.data.e;
        // console.log("SHOW CREATE MENU?", envDetails);
        dispatch(getEnvSuccessAction(envDetails));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });

  }
}
/************** */
export const selectAATeamSuccessAction = (
  selectedAATeamName: string
): SelectAATeamSuccessAction => ({
  selectedAATeamName,
  type: action_types.SELECT_AA_TEAM_SUCCESS
});

export const selectAATeamAction = (selectedAATeamName: string): SelectAATeamSuccessAction => {
  return selectAATeamSuccessAction(selectedAATeamName)
};
/****************** */
export const isAATeamLeadSuccessAction = (
  isAATeamLead: boolean
): IsAATeamLeadSuccessAction => ({
  isAATeamLead,
  type: action_types.IS_AA_TEAM_LEAD_SUCCESS
});

export const isAATeamLead = (
  isAATeamLead: boolean
): IsAATeamLeadSuccessAction => {
  return isAATeamLeadSuccessAction(isAATeamLead)
};
/****************** */