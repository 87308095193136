import * as types from '../../../../actions/action-types';
import { SVLearnerDetailsAction } from './learner-selection-list.actions';
import  Learner from '../../../../models/learner';

import initialState from '../../../../reducers/initialState';

export function SVLearnerSelectionReducer(
      state: Learner[] = initialState.SVLearnerSelectionList,
      action: SVLearnerDetailsAction
    ): Learner[] {
        if (action.type === types.GET_STANDARDS_VERIFICATION_LEARNERS_SUCCESS) {
            return action.SVLearnerDetails;
        }
        else{
         return state; 
        }
    }


    