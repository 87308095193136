import * as React from 'react';
import moment from 'moment';
import { SampleRequest } from '../../../models/sample-request';
import {Button,TextInput,DatePicker} from 'ukas.uux.react-components';
// const PearsonElementsSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');

export interface CreateMediaSetFormProps {
  sampleRequest: SampleRequest
  onFieldChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onDeadlineChanged: (newValue: string) => void;
  onSave: (event: Event) => void;
  onClear: () => void;
  errors: any;
  saveButtonEnabled: boolean;
}

const CreateSampleRequestForm = ({
  sampleRequest,
  onFieldChange,
  onSave,
  onClear,
  onDeadlineChanged,
  errors,
  saveButtonEnabled
}: CreateMediaSetFormProps) => {
  const onSubmit = (event: any) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    form.reset();
    onClear();
  };

  return (
    <div>
    <form className="pe-form" onSubmit={onSubmit}>
    <div className="pe-form--group">
        <TextInput id="academicYear"
          label='Academic Year'
          value={sampleRequest.academicYear}
          feedbackType={errors.academicYear !== undefined ? 'invalid' : 'valid'}
          feedback={errors.academicYear}
          onChange={onFieldChange} 
          maxLength={10}/></div> 
          {/* TODO Change maxLength accordingly*/}
    <div className="pe-form--group">
        <TextInput id="allocationId"
          label='Allocation id'
          value={sampleRequest.allocationId}
          feedbackType={errors.allocationId !== undefined ? 'invalid' : 'valid'}
          feedback={errors.allocationId}
          onChange={onFieldChange}
          maxLength={10}/></div>
      <div className="pe-form--group">
        <TextInput id="qualificationId"
          label='Qualification id'
          value={sampleRequest.qualificationId}
          feedbackType={errors.qualificationId !== undefined ? 'invalid' : 'valid'}
          feedback={errors.qualificationId}
          onChange={onFieldChange}
          maxLength={10} /></div>
      <div className="pe-form--group">
        <TextInput id="qualificationName"
          label='Qualification Group'
          value={sampleRequest.qualificationName}
          feedbackType={errors.qualificationName !== undefined ? 'invalid' : 'valid'}
          feedback={errors.qualificationName}
          onChange={onFieldChange}
          maxLength={10} />
      </div>
      <div className="pe-form--group">
        <TextInput id="qualificationGroupCode"
          label='Qualification Group Code'
          value={sampleRequest.qualificationGroupCode}
          feedbackType={errors.qualificationGroupCode !== undefined ? 'invalid' : 'valid'}
          feedback={errors.qualificationGroupCode}
          onChange={onFieldChange}
          maxLength={10} />
      </div>
      <div className="pe-form--group">
        <TextInput
          id="learningProviderId"
          label={'Learning provider id'}
          value={sampleRequest.learningProviderId}
          feedbackType={
            errors.learningProviderId !== undefined ? 'invalid' : 'valid'
          }
          feedback={errors.learningProviderId}
          onChange={onFieldChange}
          maxLength={10}
        />
      </div>
      <div className="pe-form--group">
        <TextInput
          id="learningProviderName"
          label={'Learning provider name'}
          value={sampleRequest.learningProviderName}
          feedbackType={
            errors.learningProviderName !== undefined ? 'invalid' : 'valid'
          }
          feedback={errors.learningProviderName}
          onChange={onFieldChange}
          maxLength={10}
        />
      </div>
      <div className="pe-form--group">
        <TextInput
          id="learningProviderCode"
          label={'Learning Provider Code'}
          value={sampleRequest.learningProviderCode}
          feedbackType={
            errors.learningProviderCode !== undefined ? 'invalid' : 'valid'
          }
          feedback={errors.learningProviderCode}
          onChange={onFieldChange}
          maxLength={10}
        />
      </div>
      <div className="pe-form--group">
      <TextInput
          id="assessmentAssociateId"
          label={'Standards Verifier Id (AA Number)'}
          value={sampleRequest.assessmentAssociateId}
          feedbackType={errors.assessmentAssociateId !== undefined ? 'invalid' : 'valid'}
          feedback={errors.assessmentAssociateId}
          onChange={onFieldChange}
          maxLength={10}
        />
      </div>
      <div className="pe-form--group">
      <TextInput
          id="assessmentAssociateName"
          label={'Standards Verifier Name (AA Name)'}
          value={sampleRequest.assessmentAssociateName}
          feedbackType={errors.assessmentAssociateName !== undefined ? 'invalid' : 'valid'}
          feedback={errors.assessmentAssociateName}
          onChange={onFieldChange}
          maxLength={10}
        />
      </div>
      <div className="pe-form--group">
        <DatePicker
          id="deadlineDateTime"
          label="Select deadline date (dd/mm/yyyy)"
          value={sampleRequest.proposedSamplingDate? moment(sampleRequest.proposedSamplingDate).format("YYYY-MM-DD") : ""}
          feedbackType={
            errors.deadlineDateTime !== undefined ? 'invalid' : 'valid'
          }
          feedback={errors.deadlineDateTime}
          onChange={()=>onDeadlineChanged}
        />
      </div>
      <div className="pe-strategy__centered--small-gap">
        <div>
          <Button
            id="saveButton"
            onClick={()=>onSave}
            disabled={saveButtonEnabled === false ? true : false }
          >
            Submit
              </Button>
          &nbsp;
              <Button id="clearButton" onClick={onClear}>
            Clear
              </Button>
        </div>
      </div>
    </form><div className="pe-strategy__centered--small-gap">&nbsp;</div></div>
  );
};

export default CreateSampleRequestForm;
