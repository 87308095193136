import * as types from '../../actions/action-types';
import { SampleStandardsVerificationRequestsAction } from './sample-standards-verification-request.actions';
import { SampleRequest } from '../../models/sample-request';
import { ProxyAATeamMember } from '../../models/team';
import initialState from '../../reducers/initialState';

export function SampleStandardsVerificationRequestReducer(
  state: SampleRequest[] = initialState.sampleRequests,
  action: SampleStandardsVerificationRequestsAction 
): SampleRequest[] {
  switch (action.type) {
    case types.GET_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS:      
      return action.sampleRequests;
    case types.UPDATE_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS:
            // media sets minus the one we want to mutate
            const sampleRequests = state.filter(r => r.allocationId !== action.sampleRequest.allocationId);

            // return the original media sets plus the mutated one
            return [...sampleRequests, Object.assign({}, action.sampleRequest)];
    default:
      return state;
  } 
}

export function UpdateProxyAATeamMemberReducer(
  state: ProxyAATeamMember = initialState.proxyAATeamMember,
  action: SampleStandardsVerificationRequestsAction 
): ProxyAATeamMember {
  // if(action.type === types.UPDATE_PROXT_AA_TEAM_MEMBER){
  //   console.log(action);
  // }
  if (action.type === types.UPDATE_PROXT_AA_TEAM_MEMBER) {
    return action.proxyAATeamMember;
  }
    else {
      return state;
  } 
}

export const SampleStandardsVerificationRequestCompletedReducer = (
  state: boolean = initialState.isGetSampleMediaRequestCompleted,
  action: SampleStandardsVerificationRequestsAction 
) => {
  if (action.type === types.GET_SAMPLE_MEDIA_REQUESTS_COMPLETED) {
    return action.isGetSampleMediaRequestCompleted;
    }
    else{
     return state;
    }
     
}

export const SampleStandardsVerificationRequestStartedReducer = (
  state: boolean = initialState.isGetSampleMediaRequestStarted,
  action: SampleStandardsVerificationRequestsAction 
) => {
  if (action.type=== types.GET_SAMPLE_MEDIA_REQUESTS_STARTED) {
 
      return action.isGetSampleMediaRequestStarted
  }
   else{
    return state;
   }
    
  
}