import { SelectedUnit } from './unit'
export class SampleRequest {  
    academicYear: string;
    allocationId: string;
    qualificationId: string;
    qualificationName: string;
    qualificationGroupCode: string;
    consortiaId: number;
    learningProviderId: string;
    learningProviderCode: string;
    learningProviderName: string;
    assessmentAssociateId: string;
    failureReason: string;
    assessmentAssociateName: string;
    standardsVerifierName: string;
    standardsVerificationSampleId:string;
    proposedSamplingDate: Date;
    createdDateTime: Date;
    deadLineDateTime:  Date = new Date();
    lastModifiedDateTime:  Date;
    status: string;
    previousState: string;
    selectedUnits: SelectedUnit[];
    submittedUnits!: SelectedUnit[];
    mediaSetId: string; 
    submittedBy: string;
    submissionDate:Date; 
    version: string='';
    parentRequests: string[];
    parentLearningProviderCode: string;
}