import * as React from "react";
// import Unit from "../../../models/unit";
// import { statusEnums } from "../../shared/enums";

interface TeamMemberListRowProps {
  
  onTeamMemberSelect: (
    assessmentAssociateId: string,
    AATeamMember:string[],
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  teamMember: any;
  
}
const TeamMemberListRow = ({
  onTeamMemberSelect,
  teamMember
}: TeamMemberListRowProps) => {
 let isSelected = false;
 let isTeamMemberaa : any = localStorage.getItem("isTeamMemberaa");   
 if(isTeamMemberaa === teamMember.aaNumber){
   isSelected = true
 }
  return (
    
    <tr className={isSelected ? "team-member-selected" : "team-member-not-selected"}onClick={onTeamMemberSelect.bind(event,teamMember.assessmentAssociateId,teamMember)} > 
      <td>
      {teamMember.aaNumber} {teamMember.firstName} {teamMember.lastName}
      </td>
    </tr>
  );
};

export default TeamMemberListRow;
