import * as React from "react";
import ConfirmationModal from './confirmation-modal'
import { RouteComponentProps, Redirect  } from "react-router-dom";
import * as _ from "lodash";
import * as toastr from "toastr";
import { FormattedDate, FormattedTime } from "react-intl";

import TextHelper from "../../shared/text-formatting";
// import Config from "../../../app-config";
import { MediaRequest, MediaRequestFile } from "../../../models/media-request";
import { SampleRequest } from "../../../models/sample-request";
import { LearnerUnit } from "../../../models/LearnerUnit";

import AccessLearnersMaterialList from "./access-learner-material-list";

import ViewLearnerMaterialModal from "./view-learner-material-modal";

const PearsonElementsSdk = require("@pearson-components/modal");
const pageTitle = "Access Learner Material";
export interface StateProps {
  sampleMediaRequest: MediaRequest | null;
  sampleRequest: SampleRequest;
}

export interface DispatchProps {
  getLearnerMediaRequests: (
    learningProviderId: string,
    allocationId: string
  ) => Promise<void>;
  rejectSampleRequest:(
    assessmentAssociateId:string, 
    standardsVerificationSampleId: string
  ) => Promise<void>;
}

export type OwnProps = RouteComponentProps<{ allocationId: string }>;

interface Props extends DispatchProps, StateProps, OwnProps {}

interface State {
  isBusy: boolean;
  isRejected: boolean;
  selectedSampleDeadline: Date;
  selectedColumn: string;
  direction: string;
  selectedUnit: { item: string; value: string };
  showModal: boolean;
  selectedLearnerUnitCombo: LearnerUnit | null;
  submissionError: boolean;
  showConfirmModal: boolean;

}

export class AccessLearnerMaterial extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    document.title = pageTitle;
    this.state = {
      isBusy: true,
      isRejected:false,
      selectedSampleDeadline: new Date(),
      selectedColumn: "learnerId",
      direction: "",
      selectedUnit: { item: "All Units", value: "0" },
      showModal: false,
      selectedLearnerUnitCombo: null,
      submissionError: false,
      showConfirmModal: false

    };

    

    this.goBack = this.goBack.bind(this);
    this.onSelectUnit = this.onSelectUnit.bind(this);
    this.getUnits = this.getUnits.bind(this);
    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.getIconName = this.getIconName.bind(this);
    this.cancelModalHandler = this.cancelModalHandler.bind(this);
    this.successModalHandler = this.successModalHandler.bind(this);
    this.showFiles = this.showFiles.bind(this);
    this.sort = this.sort.bind(this);
    this.getIconName = this.getIconName.bind(this);
    this.showFiles = this.showFiles.bind(this); 
    this.handleRejectSampleRequest = this.handleRejectSampleRequest.bind(this);
    this.onReject = this.onReject.bind(this); 
    this.cancelConfirmModalHandler = this.cancelConfirmModalHandler.bind(this);
    this.successConfirmModalHandler = this.successConfirmModalHandler.bind(this);  
  }

  render() {
    const {
      showModal,
      selectedLearnerUnitCombo: selectedLearnerUnit,
      isRejected
    } = this.state;

    const { isBusy } = this.state;
    
    const confirmSubmittext = {
      headerTitle: 'Reject Confirmation',
      bodyText: '',
      closeButtonSRText: 'Cancel',
      modalSaveButtonText: 'Ok',
      modalCancelButtonText: 'Cancel'
    };
    return (
      <div className="learners">
        {isRejected && <Redirect to='/sample-requests'  />}
        <div className="pe-template__single">
          <h1 className="pe-page-title">{pageTitle}</h1>
        </div>
        <div className="pe-template__single content">
          <section>
            <p className="pe-strategy__centered--small-gap">
              Access uploaded material for unit(s) and learner(s) within the
              sample request.
            </p>
            
            {!isBusy && this.props.sampleMediaRequest && this.props.sampleRequest && (
              <div>
                <div className="pe-row">
                  <div className="pe-col-6">
                    <div className="pe-label small-gap">
                      <span className="pe-label pe-label--bold">
                        Learning Provider
                      </span>
                      <br />
                      {  (this.props.sampleRequest.parentLearningProviderCode ? this.props.sampleRequest.parentLearningProviderCode : this.props.sampleRequest.learningProviderCode) +
                          " " +
                          this.props.sampleRequest.learningProviderName}
                          &nbsp;
                    <b>{this.props.sampleRequest.consortiaId ? '(C)': '' }</b>
                    </div>
                  </div>
                  <div className="pe-col-6">
                    <div className="pe-label small-gap">
                      <span className="pe-label pe-label--bold">
                        Qualification
                      </span>
                      <br />
                      {this.props.sampleRequest.qualificationGroupCode +
                        " " +
                        this.props.sampleMediaRequest.qualificationName}&nbsp;
                        <b className="second-sample-text-color">{this.props.sampleRequest.parentRequests && this.props.sampleRequest.parentRequests.length > 0 ? "("+(this.props.sampleRequest.parentRequests.length + 1)+")" : ""}</b>
                    </div>
                  </div>
                </div>
                <div className="pe-row">
                  <div className="pe-col-6">
                    <div className="pe-label small-gap">
                      <span className="pe-label pe-label--bold">Status</span>
                      <br />
                      {TextHelper.titleCaseUploadStatus(
                        this.props.sampleRequest.status
                      )}
                    </div>
                  </div>{" "}
                  <div className="pe-col-6">
                    <div className="pe-label small-gap">
                      <span className="pe-label pe-label--bold">
                        Sample request submitted on
                      </span>
                      <br />
                      {this.props.sampleRequest.status && (
                        <FormattedDate
                          value={this.props.sampleRequest.submissionDate}
                          year="numeric"
                          month="numeric"
                          day="numeric"
                        />
                      )}{" "}
                      {
                        <FormattedTime
                          value={this.props.sampleRequest.submissionDate}
                        />
                      }{" "}
                      {this.props.sampleRequest.submittedBy &&
                        this.props.sampleRequest.submittedBy.length && (
                          <span>
                            {" "}
                            by {this.props.sampleRequest.submittedBy}
                          </span>
                        )}
                    </div>
                  </div>
                </div>

                <div className="pe-row">
                  <div className="pe-col-6">
                    <div className="pe-label small-gap">
                      <span className="pe-label pe-label--bold">
                        Sample received on
                      </span>
                      <br />
                      {this.props.sampleMediaRequest.submissionDate && (
                        <FormattedDate
                          value={this.props.sampleMediaRequest.submissionDate}
                          year="numeric"
                          month="numeric"
                          day="numeric"
                        />
                      )}{" "}
                      {this.props.sampleMediaRequest.submissionDate && (
                        <FormattedTime
                          value={this.props.sampleMediaRequest.submissionDate}
                        />
                      )}
                      {this.props.sampleMediaRequest.submittedBy &&
                        this.props.sampleMediaRequest.submittedBy.length && (
                          <span>
                            {" "}
                            from {this.props.sampleMediaRequest.submittedBy}
                          </span>
                        )}
                    </div>
                  </div>{" "}
                  <div className="pe-col-6" />
                </div>

                <AccessLearnersMaterialList
                  sampleMediaRequestLearners={this.sort(
                    this.filterLearnersByUnit(
                      this.props.sampleMediaRequest.learnerUnits,
                      this.state.selectedUnit.value
                    )
                  )}
                  learningProviderCode= {this.props.sampleMediaRequest.learningProviderCode}
                  learningProviderName = {this.props.sampleMediaRequest.learningProviderName}
                  mediaRequestFiles={this.props.sampleMediaRequest.files}
                  units={this.getUnits(
                    this.props.sampleMediaRequest.learnerUnits
                  )}
                  onSelectUnit={this.onSelectUnit}
                  goBack={this.goBack}
                  unitFilterLabel={this.state.selectedUnit.item}
                  showFiles={this.showFiles}
                  onSort={this.handleColumnSort}
                  getIconName={this.getIconName}
                  currentStatus={this.props.sampleRequest.status} 
                  onReject={this.onReject}
                  isConsortia ={ this.isConsortia() }
                  isTeamMember = {this.isTeamMember()}
                />
                <ViewLearnerMaterialModal
                  showModal={showModal}
                  cancelModalHandler={this.cancelModalHandler}
                  successModalHandler={this.successModalHandler}
                  filterFilesForLearner={this.filterFilesForLearner}
                  learnerUnit={selectedLearnerUnit!}
                  files={this.props.sampleMediaRequest.files}
                  learningProvideId={
                    this.props.sampleMediaRequest.learningProviderId
                  }
                  parentRequests = {this.props.sampleRequest.parentRequests}
                  mediaSetId={this.props.sampleMediaRequest.mediaSetId}
                  qualification={
                    this.props.sampleRequest.qualificationGroupCode +
                    " " +
                    this.props.sampleMediaRequest.qualificationName
                  }
                />

            <PearsonElementsSdk.Modal isShown={this.state.showConfirmModal}
              text={confirmSubmittext}
              footerVisible={false}
              cancelBtnHandler={this.cancelConfirmModalHandler}
              successBtnHandler={this.successConfirmModalHandler}>
              <ConfirmationModal 
                cancelBtnHandler={this.cancelConfirmModalHandler}
                successBtnHandler={this.successConfirmModalHandler} 
              />
            </PearsonElementsSdk.Modal>
              </div>
            )}
            {isBusy && (
              <div className="pe-template__single content">Loading...</div>
            )}
            {/* { !this.props.sampleMediaRequest ||
              (!this.props.sampleRequest && (
                <div className="pe-template__single content">Loading...</div>
              ))} */}
          </section>
        </div>
      </div>
    );
  }
  // componentWillReceiveProps(nextProps: Props) {
  // const uploadsInProgress: number = nextProps.mediaUploadStatus
  //   ? nextProps.mediaUploadStatus.length
  //   : 0;
  // this.setState({ uploadsInProgress });
  // }


  isConsortia(){
    if(this.props.sampleRequest.consortiaId) {
    return true
    } 
    else {
    return false;
    }
  }

  componentDidMount() {
    if (this.props.sampleRequest) {
      // console.log("ALM--Component Did Mount:::",this.props.sampleRequest);
      this.props.getLearnerMediaRequests(
        this.props.sampleRequest.learningProviderId,
        this.props.sampleRequest.mediaSetId
      ).then(()=>{ 
        this.setState({isBusy: false})
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.sampleRequest && this.props.sampleRequest) {
      // console.log("ALM--Component Did Update:::",this.props.sampleRequest);
      this.props.getLearnerMediaRequests(
        this.props.sampleRequest.learningProviderId,
        this.props.sampleRequest.mediaSetId
      ).then(()=>{ 
        this.setState({isBusy: false})
      });
    }
  }

  cancelModalHandler() {
    this.setState({ showModal: false });
  }

  successModalHandler() {
    this.setState({ showModal: false });
    toastr.success("Success!!");
  }  

  filterFilesForLearner = (files: MediaRequestFile[], learnerUnit: LearnerUnit) => {
    const filteredFiles: Array<
      MediaRequestFile | undefined
    > = files.filter(
      (f: MediaRequestFile) =>
        f.learnerIds.filter((l: string) => l === learnerUnit.learnerId).length >
          0 && f.unitId === learnerUnit.unitId
    );
    // learnerUnit.fileIds.map(luf => _.find(files, f => f.fileId === luf));
    // .filter(files, (f) => learnerUnit.fileIds.includes(f.fileId));    
    
    const filteredFilesWithBytes = filteredFiles.map(elem => {
      if(elem && elem.transformedFileKey)
      {
        elem.orgFileName = elem.fileName;
        elem.fileName = elem.transformedFileKey.substring(elem.transformedFileKey.lastIndexOf('/')+1);
        // elem.fileId = elem.transformedFileKey;
        elem.fileId = elem.fileId;
      }
      if(elem && elem.transformedFileSize)
      {
        elem.fileSize = elem.transformedFileSize;
      }
      return (elem && elem.fileSize) ? {...elem, fileSizeBytes: this.sizeInBytes(elem.fileSize) } : {...elem, fileSizeBytes: 0}
    });
    return filteredFilesWithBytes.filter(  x => x !== undefined ) as MediaRequestFile[];
    
  }

  sizeInBytes = (filesizeStr: string) => {
      const str = filesizeStr;
      if (!str) {
        return 0;
      }
      const sizeStr = str.replace(/[0-9\. ]/g, "").toUpperCase();
      const size = parseFloat(str.replace(/[a-zA-Z ]/g, ""));
      let fileSizeNum = 0;
      switch (sizeStr) {
        case "BYTES":
          fileSizeNum = size;
          break;
        case "KB":
          fileSizeNum = Math.floor(size * Math.pow(1024, 1));
          break;
        case "MB":
          fileSizeNum = Math.floor(size * Math.pow(1024, 2));
          break;
        case "GB":
          fileSizeNum = Math.floor(size * Math.pow(1024, 3));
          break;
      }
      return fileSizeNum;
  }

  showFiles(
    learnerUnit: LearnerUnit,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    event.preventDefault();

    this.setState({ showModal: true, selectedLearnerUnitCombo: learnerUnit });
  }

  getIconName = (selectedColumn: string) => {
    if (this.state.selectedColumn === selectedColumn) {
      return this.state.direction === "up" ? "sort-up-18" : "sort-down-18";
    }

    return "sortable-18";
  };

  handleColumnSort = (selectedColumn: string) => {
    return () => {
      let direction = "down";

      if (this.state.selectedColumn === selectedColumn) {
        direction = this.state.direction === "up" ? "down" : "up";
      }

      this.setState({ selectedColumn, direction });
    };
  };

  sort(learners: LearnerUnit[]): LearnerUnit[] {
    const lodashDirection = this.state.direction === "down" ? "desc" : "asc";

    const sortedlearners = _.orderBy(
      learners,
      [
        learner => {
          if (this.state.selectedColumn === "learnerName") {
            return `${learner.firstName} ${learner.surname}`;
          } else if (
            typeof learner[this.state.selectedColumn].toLowerCase === "function"
          ) {
           return learner[this.state.selectedColumn].toLowerCase();
          } else if (this.state.selectedColumn === "fileIds") {
            return learner[this.state.selectedColumn].length
              .toString()
              .toLowerCase();
          } else {
            return learner[this.state.selectedColumn];
          }
        }
      ],
      [lodashDirection]
    ) as LearnerUnit[];

    return sortedlearners;
  }

  goBack() {
    this.props.history.push(`/sample-requests`);
  }

  onSelectUnit(event: any) {
    const unitSelected = event.target.value;
    const unit = {item: unitSelected , value: unitSelected};
    this.setState({
      selectedUnit: unit
    });
  }

  filterLearnersByUnit(learners: LearnerUnit[], unit: string) {
    if (unit === "0") {
      return learners;
    }

    return learners.filter((l: LearnerUnit) => l.unitName === unit);
  }

  getUnits(learners: LearnerUnit[]) {
    if (learners) {
      const units = learners.map(learner => ({
        unitId: learner.unitId,
        unitName: learner.unitName
      }));
      if (units.length) {
        return _.uniqBy(units, x => x.unitId + " " + x.unitName);
      }
    }
    return [];
  }

  onReject(){
    this.setState({ showConfirmModal: true });
  }
  
  handleRejectSampleRequest(){
    if (this.props.sampleRequest) {
      this.props.rejectSampleRequest(
        this.props.sampleRequest.assessmentAssociateId,
        this.props.sampleRequest.standardsVerificationSampleId
      ).then(()=>{ 
        this.setState({isBusy: false})
        this.setState({isRejected:true})
      });
    }
  } 

  cancelConfirmModalHandler() {
    this.setState({ showConfirmModal: false });
  } 

  successConfirmModalHandler() {
    this.handleRejectSampleRequest();    
    this.setState({ showConfirmModal: false });
  }

  isTeamMember():boolean {    
    let isTeamMember = false;
    const proxyAATeamMember = localStorage.getItem('isTeamMember')
    if(proxyAATeamMember === 'true'){
      isTeamMember =true;
    }
    return isTeamMember;
  }

}

export default AccessLearnerMaterial;
