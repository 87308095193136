function titleCaseUploadStatus(str: string) {
    if(str){
    return str.toLowerCase().split('_').map(word => (word.replace(word[0], word[0].toUpperCase()))).join(' ');
    }

    return '';
}
function getStatusText(status: string) {
    return status.includes('_') ? titleCaseUploadStatus(status) : status;
}
export default { titleCaseUploadStatus, getStatusText };