import { Reducer } from 'redux';
import initialState from './initialState';

// import { AuthAction } from '../actions/auth/auth.actions';
// import { AUTH_USER } from '../actions/action-types';
import { UserDetailsAction } from '../features/shared/user.actions';
import { GET_ASSESSMENTASSOCIATE_DETAILS_SUCCESS } from '../actions/action-types';
//  import auth from '../actions/auth/auth'
import { AssessmentAssociate, Nullable } from '../models/assessment-associate';

//  const authForgeRock = new auth();

// const assessmentAssociates = [
//   { id: '700001', firstName: 'SV', surname: 'One', username: 'sv.one@mail.com', examinerId: '700001' },
//   { id: '2', firstName: 'SV', surname: 'Two', username: 'sv.two@mail.com', examinerId: '700002' },
//   { id: '3', firstName: 'SV', surname: 'Three', username: 'sv.three@mail.com', examinerId: '700003' },
//   { id: '700004', firstName: 'SV', surname: 'Four', username: 'sv.four@mail.com', examinerId: '700004' },
//   { id: '5', firstName: 'SV', surname: 'Five', username: 'sv.five@mail.com', examinerId: '700005' },
//   { id: '700006', firstName: 'SV', surname: 'Six', username: 'sv.six@mail.com', examinerId: '700006' },
//   { id: '7', firstName: 'SV', surname: 'Seven', username: 'sv.seven@mail.com', examinerId: '700007' },
//   { id: '8', firstName: 'SV', surname: 'Eight', username: 'sv.eight@mail.com', examinerId: '700008' },
//   { id: '9', firstName: 'SV', surname: 'Nine', username: 'sv.nine@mail.com', examinerId: '700009' },
//   { id: '10', firstName: 'SV', surname: 'Ten', username: 'sv.ten@mail.com', examinerId: '700010' },
//   { id: '11', firstName: 'SV', surname: 'Eleven', username: 'sv.eleven@mail.com', examinerId: '700011' }

// ];

export const assessmentAssociateReducer: Reducer<Nullable<AssessmentAssociate>> = (
  state: Nullable<AssessmentAssociate> = initialState.assessmentAssociate,
  action: UserDetailsAction
) => {

  if (action.type === GET_ASSESSMENTASSOCIATE_DETAILS_SUCCESS) {
          return action.assessmentAssociateDetails;
  }
   else{
  
      return state;
  }
};
