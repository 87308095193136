export default class  AATeam {    
    teamName: string;
    academicYear: string;
    teamLeader: TeamMember;
    teamMembers: TeamMember[];     
}
export class TeamMember{
    assessmentAssociate: {
    aaNumber: string;
    firstName: string;
    lastName: string;
    }
}
export class ProxyAATeamMember{
    teamName:string;
    firstName: string;
    lastName: string;
    assessmentAssociateId: number;
    aaNumber:string;
   }