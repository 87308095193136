import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as _ from 'lodash';

import {
  SampleStandardsVerificationRequests,
  StateProps,
  DispatchProps,
} from './sample-standards-verification-request-list.component';


import { StoreState } from '../../../store/store-state';
import { SampleRequest } from '../../../models/sample-request'; 
import { SampleStandardsVerificationRequestsAction, refreshSampleSVRequest,countSampleSVRequest, getSampleMediaRequestCompleted,refreshRequest } from '../../shared/sample-standards-verification-request.actions';

const mapStateToProps = (state: StoreState): StateProps => {
  const sampleRequests = _.orderBy(state.sampleRequests, (o: SampleRequest) => o.qualificationName, 'asc');
  
  return {     
    sampleRequests,
    profile: state.auth.profile,
    lpExclusions : state.userDetails.lpExclusions,
    userPermissionValidated: state.userPermissionValidated,
    isGetSampleMediaRequestCompleted: state.isGetSampleMediaRequestCompleted,
    isGetSampleMediaRequestStarted: state.isGetSampleMediaRequestStarted
  };
};

const mapDispatchToProps = ( 
  dispatch: ThunkDispatch<
    StoreState,
    void,
    SampleStandardsVerificationRequestsAction 
  >
): DispatchProps => ({
  refreshSampleSVRequest: (sampleRequest: SampleRequest) => dispatch(refreshSampleSVRequest(sampleRequest)),
  countSampleSVRequest: (assessmentAssociateId: string) => dispatch(countSampleSVRequest(assessmentAssociateId)),
  getSampleMediaRequestCompleted: status => dispatch(getSampleMediaRequestCompleted(status)),
  refreshRequest: (assessmentAssociateId: string) => dispatch(refreshRequest(assessmentAssociateId)),
});

export default connect<StateProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(SampleStandardsVerificationRequests); 
