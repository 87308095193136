import * as React from 'react';

interface Props {
    render:() => JSX.Element;
    selectedOptionText?: string;
}

interface State {
    showMenu: boolean;
}

class DropdownMenu extends React.Component<Props, State> {
  dropdownMenu: HTMLDivElement | null;
  constructor(props:Props) {
    super(props);
    
    this.state = {
      showMenu: false,
    };    

    this.showMenuClick = this.showMenuClick.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  
  showMenuClick() {

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click',event => this.closeMenu);
    });
  }
  
  closeMenu() {
    
    // if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
      
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });  
      
    // }
  }

  render() {
    const isMenuOpen = this.state.showMenu;
    const toggleClassNames =  { 'open':'-icon', 'close': '-icon-open' };
    
    // console.log("className",(!isMenuOpen?toggleClassNames.open:toggleClassNames.close));
    const iconClass = !isMenuOpen?toggleClassNames.open:toggleClassNames.close;
    return (      
      <span className="dropdown-menu-right" onClick={this.state.showMenu ? this.closeMenu : this.showMenuClick}>
        <span className="dropdown-selected-option">
          {this.props.selectedOptionText?this.props.selectedOptionText:'Please Select'}
        </span>
        <span className={'dropdown' + iconClass} onClick={this.state.showMenu ? this.closeMenu : this.showMenuClick} />     
        
        {
          this.state.showMenu
            ? (
            <div className="dropdown-list-box">
              <div
                className="link-holder"                 
                ref={(element) => {
                  this.dropdownMenu = element;
                }}
              >
                {this.props.render()}
              </div>
            </div>
            )
            : (
              null
            )
        }
      </span>
    );
  }
}

export default DropdownMenu;