import { connect } from "react-redux";
import * as _ from "lodash";
import { ThunkDispatch } from "redux-thunk";

import { StoreState } from "../../../store/store-state";
import Unit from "../../../models/unit";
import { SampleRequest } from "../../../models/sample-request";
import { AssessmentAssociate } from "../../../models/assessment-associate";

import {
  GlobalAlertListActions,
  addGlobalAlertAction
} from '../global-alert-list/ducks/global-alert-list.actions';
import { GlobalAlertMessage } from "../../../models/global-alert-message";

import {
  submitSampleToLearningProvider,
  recallSampleRequestAction,
  resetSampleRequestAction,
  SVLearnerDetailsAction
} from './ducks/link-learners.actions';

import { getStandardVerificationUnits } from "../unit-selection/ducks/unit-selection-list.actions";

import {
  LinkLearners,
  StateProps,
  DispatchProps,
  OwnProps
} from "./link-learners.component";

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  const sampleRequest = _.find(state.sampleRequests, {
    allocationId: ownProps.match.params.allocationId
  }) as SampleRequest;

  // const display = ownProps.match.params.display as string;

  const SVUnitList = _.orderBy(
    state.SVUnitSelectionList,
    (o: Unit) => o.name,
    "asc"
  );

  const assessmentAssociate = state.assessmentAssociate as AssessmentAssociate;

  return {
    sampleRequest,
    SVUnitDetails: SVUnitList,
    assessmentAssociate
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, void, SVLearnerDetailsAction | GlobalAlertListActions>
): DispatchProps => ({
  submitSampleToLearningProvider: submitSampleObj =>
    dispatch(submitSampleToLearningProvider(submitSampleObj)),

  getStandardVerificationUnits: (learningProviderId, qualificationId) =>
    dispatch(getStandardVerificationUnits(learningProviderId, qualificationId)),

  raiseAlert: (globalAlertMessage: GlobalAlertMessage) =>
    dispatch(addGlobalAlertAction(globalAlertMessage)),

  recallSampleRequest: submitSampleObj =>
    dispatch(recallSampleRequestAction(submitSampleObj)),
  
  resetSampleRequest: resetSampleObj =>
    dispatch(resetSampleRequestAction(resetSampleObj))
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(LinkLearners);
