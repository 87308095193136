import { connect } from 'react-redux';
import _ from 'lodash';
import { StoreState } from "../../../store/store-state";

import { ThunkDispatch } from "redux-thunk";

import {
  CreateSampleRequests, 
  OwnProps,
  StateProps,
  DispatchProps
} from './create-sample-request.component';
import { getEnv } from '../../shared/user.actions'

import {
  SampleStandardsVerificationRequestsAction
} from '../../shared/sample-standards-verification-request.actions';

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  const userPermissionValidated:boolean = state.userPermissionValidated;
  const showCreateMenu:boolean = state.showCreateMenu;

  return {userPermissionValidated, showCreateMenu}
}
const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, void, SampleStandardsVerificationRequestsAction>
): DispatchProps => ({
  getEnv:() => dispatch(getEnv())
});
export default connect<StateProps, {}>(mapStateToProps, mapDispatchToProps)(CreateSampleRequests);
