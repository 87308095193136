import * as React from "react";
import * as _ from "lodash";
import { RouteComponentProps } from "react-router-dom";
// const PearsonElementsSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');
import {Button,TextInput} from 'ukas.uux.react-components';

export interface StateProps {
    sampleRequestCount: number 
  }
  

export interface DispatchProps {   
    getProxySampleRequests: (assessmentAssociateId: string) => Promise<void>;
  }

  export type OwnProps = RouteComponentProps<{}>;

  interface Props extends DispatchProps, StateProps, OwnProps {   
    onClose: (hb:boolean)=>void; 
    onFieldChange: (event: React.FormEvent<HTMLInputElement>) => void;
    errors: any; 
  }
  
  interface State {    
    searchText: string;
    validations: Validations,
    searchFailed:boolean,
  }
  class Validations {
    errors:any = {};
    validationFlags: ValidationFlags = new ValidationFlags;
  }
  
  class ValidationFlags { searchText:boolean = false; }

  export class SearchModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);  
        this.state = { 
          searchText: "", searchFailed:false,
          validations: new Validations()
        };
        this.searchAssessmentAssociate = this.searchAssessmentAssociate.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
    }

    render() {
        return (
          <div >
            <div className="pe-template__single">
              &nbsp;
            </div>
            <div className="pe-template__single">
              Search for an AA with sample requests to populate the request screen. 
            </div>
            <div className="pe-template__single content">
              <form className="pe-form" >
                <div className="pe-form--group">
                  <div className="searchBox"><TextInput id="searchTerm"
                    label='AA Number'
                    value={this.state.searchText}
                    feedbackType={this.state.validations.errors.searchText !== undefined ? 'invalid' : 'valid'}
                    feedback={this.state.validations.errors.searchText}
                    onChange={this.onFieldChange} maxLength={10} /></div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div className="searchButton"><Button
                    id="saveButton"
                    variant="primary"
                    onClick={(e) => this.searchAssessmentAssociate(e)}
                  >
                    Search
                  </Button></div>
              </div>
            </form>
            </div>
            <div className="small-gap">&nbsp;</div>            
            <div/>
        </div>
      )
    }

    searchAssessmentAssociate = (event:any) => {
      event.preventDefault();
      if (this.state.searchText.length) {
        // fetch the last known SV with sample requests
        const prevProxyAAId = localStorage.getItem('proxyAAId');        
        // save the current search        
        localStorage.setItem('proxyAAId', this.state.searchText);

        this.props.getProxySampleRequests(this.state.searchText)
        .then(res =>{
          if(this.props.sampleRequestCount === -1){ // No reuests or invalid AA
            const validations = Object.assign({}, this.state.validations, this.validateInputElement('searchText', "empty"));
            
            this.setState({searchFailed:true});
            // get the data for last known SV with sample requests          
            this.props.getProxySampleRequests(prevProxyAAId+'');
            // discard the current search and save the last known SV with sample requests
            localStorage.setItem('proxyAAId', prevProxyAAId+'');

            return this.setState({validations });
          } else {
            this.closeEvent();
            this.setState({searchFailed:false});            
          } 
        })
        .catch( err => { 
          const validations = Object.assign({}, this.state.validations, this.validateInputElement('searchText', "empty"));
          this.setState({validations });
        })      
      } else {
        const validations = Object.assign({}, this.state.validations, this.validateInputElement('searchText', ""));
        this.setState({validations });
      }
    }
    closeEvent = ()=>{
      const searchSuccess = !this.state.searchFailed;
      this.props.onClose(searchSuccess);
    }
    handleChange = (event: React.FormEvent<HTMLInputElement>)=> {
      this.setState({ 
        searchText: event.currentTarget.value, 
      })
    }

    validateInputElement(prop:string, value:any):Validations {       
      const result:Validations = Object.assign({}, this.state.validations);
  
      if(prop=== 'searchText') {
     {
          if (value === '') {
            result.errors[prop] = 'required';
            result.validationFlags[prop]= false;
          }else if(value === 'empty'){
            result.errors[prop] = 'There are no sample requests for this AA';
            result.validationFlags[prop]= false;
          } else {
            result.errors[prop] = undefined;
            result.validationFlags[prop] = true;
          }
        }
      }
  
      return result;
    }

    onFieldChange(event: React.FormEvent<HTMLInputElement>) {
      event.preventDefault();
  
      const element = event.target as HTMLInputElement;    
      const validations = Object.assign({}, this.state.validations, this.validateInputElement(element.id, element.value));
  
      return this.setState({ searchText:element.value,validations });
      
    }

  }
  

  export default SearchModal;