import { connect } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';
import { SelectTeamMember, StateProps, DispatchProps } from "./select-team-member.component";
import { StoreState } from "../../store/store-state";
// import AATeam from "../../models/team";

import * as _ from "lodash";
// import {
//   getMediaSetsByLearningProviderCode,
  
//   getSampleMediaRquestsSuccessAction
// } from "../../actions/select-learning-provider.actions";

import {
  getSampleStandardsVerificationRequests,
  updateProxyAATeamMember,
  SampleStandardsVerificationRequestsAction
} from '../../features/shared/sample-standards-verification-request.actions';

const mapStateToProps = (state: StoreState): StateProps => {  
  // SampleMediaRequestsList: state.sampleMediaRequests
  // return {mediaUploadStatus: []}
  // const sampleMediaRequests = _.orderBy(state.sampleMediaRequests, (o: MediaRequest) => o.qualificationName, 'asc');
  let selectedAATeamName= ''
  
   
 selectedAATeamName = state.selectedAATeamName;
  const selectedAATeam =  _.find(state.aaTeams, f => f.teamName === selectedAATeamName)
  
  

  // selectedAATeamName = state.selectedAATeamName?state.selectedAATeamName:'';
  
  // if(state.aaTeams){
    // selectedAATeam = _.find(state.aaTeams, {
    //   teamName: selectedAATeamName
    // }) as AATeam;
    // selectedAATeam = state.aaTeams;
    
  // }

  return {
    selectedAATeamName,
    selectedAATeam,
      };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    StoreState,
    void,
    SampleStandardsVerificationRequestsAction
  >
): DispatchProps => ({
  loadSampleStandardsVerificationRequests: assessmentAssociateId =>
    dispatch(getSampleStandardsVerificationRequests(assessmentAssociateId)),
  updateProxyAATeamMember: proxyAATeamMember =>
    dispatch(updateProxyAATeamMember(proxyAATeamMember))
});

export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(SelectTeamMember);

