import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { App, AppProps, DispatchProps } from './app';
import { StoreState } from '../store/store-state';
import {
  signinUser,
  signoutUser,
  AuthAction
} from '../actions/auth/auth.actions';

import { 
  getUserDetails, 
  getAssessmentAssociateDetails, 
  validateUserPermissions, 
  getEnv, 
 // getAATeamLeadDetails,
  selectAATeamAction,
  UserDetailsAction
} from '../features/shared/user.actions'

import {
  getSampleStandardsVerificationRequests,
  updateProxyAATeamMember,
  SampleStandardsVerificationRequestsAction
} from '../features/shared/sample-standards-verification-request.actions';
import InitialState from '../reducers/initialState';

// import { AssessmentAssociate } from '../models/assessment-associate';

const mapStateToProps = (state: StoreState): AppProps => {
  const assessmentAssociate =state.userDetails;
  // let  proxyAAName = '', assessmentAssociateId, loggedInUser;
  let  proxyAAName = '', assessmentAssociateId, loggedInUser, selectedAATeamName='', aaTeams, isAATeamLead;
  let  proxyAATeamMember = InitialState.proxyAATeamMember;
  if (assessmentAssociate !== null) {
    assessmentAssociateId = assessmentAssociate.aaNumber;
  }

  if(state.sampleRequests.length){
    if(state.sampleRequests[0].assessmentAssociateId !== assessmentAssociateId){
      proxyAAName = state.sampleRequests[0].assessmentAssociateId +' ' + state.sampleRequests[0].standardsVerifierName;
    }
  }  

  if(state.userDetails){
    loggedInUser = state.userDetails;
  }

  aaTeams = state.aaTeams?state.aaTeams:[];
  selectedAATeamName = state.selectedAATeamName?state.selectedAATeamName:'';  
  isAATeamLead = state.isAATeamLead?state.isAATeamLead:false;  
  if(state.proxyAATeamMember){
    proxyAATeamMember = state.proxyAATeamMember;
  }
  /** MOCKING */
  // var isAATeamLead = false
  // var proxyAATeamMemberName = ''
  // if(loggedInUser && (loggedInUser.aaNumber === '700001' || loggedInUser.aaNumber === '700002') ){
  //   isAATeamLead = true
  //   proxyAATeamMemberName = 'TEAM LEAD'
  // } 
  /** MOCKING */

  return {
    assessmentAssociateId, 
    loading: state.ajaxCallsInProgress > 0,
    isAuthenticated: state.auth.authenticated,
    profile: state.auth.profile,
    proxyAAName,
    loggedInUser,
    isAATeamLead,
    selectedAATeamName,
    aaTeams,
    userPermissionValidated: state.userDetails.isUserPermissionValid,
    showCreateMenu: state.showCreateMenu,
    proxyAATeamMember   
  }
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    StoreState,
    void,
    SampleStandardsVerificationRequestsAction | AuthAction | UserDetailsAction
  >
): DispatchProps => ({
  signin: () => signinUser(dispatch),
  singout: () => signoutUser(dispatch),
  loadSampleStandardsVerificationRequests: assessmentAssociateId =>
    dispatch(getSampleStandardsVerificationRequests(assessmentAssociateId)),
  getLoggedInUserDetails: (forgeRockId) => dispatch(getUserDetails(forgeRockId)),
  validateUserPermissions:(family_name, loggedInUserPermissionIds) => 
    dispatch(validateUserPermissions(family_name, loggedInUserPermissionIds)),
  getEnv:() => dispatch(getEnv()),
  // getAATeamLeadDetails: (assessmentAssociateId, academicYear) =>
  //   dispatch(getAATeamLeadDetails(assessmentAssociateId, academicYear)),
  selectAATeam:(selectedAATeamName) => dispatch(selectAATeamAction(selectedAATeamName)),
  updateProxyAATeamMember: proxyAATeamMember => dispatch(updateProxyAATeamMember(proxyAATeamMember)),  
  getAssessmentAssociateDetails:(forgeRockId) => dispatch(getAssessmentAssociateDetails(forgeRockId))
});
// (family_name: string, loggedInUserPermissionIds: number[])
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);