import * as types from '../../../../actions/action-types';
import { SampleMediaRequestsAction } from './access-learner-material.actions';
import { MediaRequest } from '../../../../models/media-request';


import initialState from '../../../../reducers/initialState';

export function AccessLearnerMaterialReducer(
  state: MediaRequest[] = initialState.sampleMediaRequests,
  action: SampleMediaRequestsAction
): MediaRequest[] {
  if(action.type === types.GET_LEARNER_MEDIA_REQUESTS_SUCCESS) {
    
      return action.sampleMediaRequests;
  }
    else {
      return state;
  }
  // change
}
