import { SelectedLearner } from './learner'
export default class  Unit {    
    unitId: string;  

    code: string;
    name: string;
    mandatory: number;  
    mandatoryStr: string;
    assessmentMethod:string;
}

export class SelectedUnit {
    unitId: string;
    selectedLearners: SelectedLearner[];    
    submittedLearners!: SelectedLearner[];    
}

// export class UnitSelected {       
//     unitIds: string[]
//     constructor(){
//         this.unitIds = [];
//     }
// }