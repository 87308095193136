import { combineReducers } from 'redux';
import { StoreState } from '../store/store-state';
import { ajaxStatusReducer as ajaxCallsInProgress } from './ajax-status.reducer';

import { AuthReducer as auth } from './auth.reducer';
import { GetUserDetailsReducer as userDetails } from '../features/shared/user.reducers';
import { ValidateUserPermissionsReducer as userPermissionValidated } from '../features/shared/user.reducers';
import { GetEnvReducer as showCreateMenu } from '../features/shared/user.reducers';
import { selectAATeamReducer as selectedAATeamName } from '../features/shared/user.reducers';
import { CallbackReducer as callback } from './callback.reducer';
import { globalErrorReducer as hasGlobalError } from './global-error.reducer';
import { assessmentAssociateReducer as assessmentAssociate } from './assessment-associate.reducer';
import { SampleStandardsVerificationRequestReducer as sampleRequests } from '../features/shared/sample-standards-verification-request.reducer';
import { SampleStandardsVerificationRequestCompletedReducer as isGetSampleMediaRequestCompleted } from '../features/shared/sample-standards-verification-request.reducer';
import { SampleStandardsVerificationRequestStartedReducer as isGetSampleMediaRequestStarted } from '../features/shared/sample-standards-verification-request.reducer';
import { UpdateProxyAATeamMemberReducer as proxyAATeamMember } from '../features/shared/sample-standards-verification-request.reducer';
import { GetAATeamLeadDetailsReducer as aaTeams } from '../features/shared/user.reducers';
import { IsAATeamLeadReducer as isAATeamLead } from '../features/shared/user.reducers';
import { SVUnitSelectionReducer as SVUnitSelectionList } from '../features/sample-requests/unit-selection/ducks/unit-selection-list.reducers';
import { SVLearnerSelectionReducer as SVLearnerSelectionList } from '../features/sample-requests/learner-selection/ducks/learner-selection-list.reducers';
import { AccessLearnerMaterialReducer as sampleMediaRequests} from '../features/sample-requests/access-learner-material/ducks/access-learner-material.reducers';
import { globalAlertReducer as globalAlertMessages } from '../features/sample-requests/global-alert-list/ducks/gloal-alert-list.reducer';

const rootReducer = combineReducers<StoreState>({
    sampleRequests,
    assessmentAssociate, 
    sampleMediaRequests,
    isGetSampleMediaRequestCompleted,
    isGetSampleMediaRequestStarted,
    SVUnitSelectionList,
    hasGlobalError,
    ajaxCallsInProgress,
    auth,
    userDetails,
    showCreateMenu,
    userPermissionValidated,
    callback,
    SVLearnerSelectionList,
    isAATeamLead,
    selectedAATeamName,
    aaTeams,
    globalAlertMessages,
    proxyAATeamMember
})

export default rootReducer;