import * as React from "react";
import { SampleRequest } from "../../../models/sample-request";
import { FormattedDate } from "react-intl";
import infoFillImage from "./info-fill-18.svg";
import TextHelper from "../../shared/text-formatting";
import { Tooltip } from "ukas.uux.react-components";
// const PearsonElementsSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');
// const PearsonElementsSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');

interface SampleStandardsVerificationRequestListRowProps {
  sampleStandardsVerificationRequest: SampleRequest;
  lpExclusions:string[]
  onSelect: (
    allocationId: string,
    event: React.MouseEvent
  ) => void;
  handleClick:(
    SVStatus: string,
    SVAllocationId: string,
    filteredList:boolean,
    lpExclusionsModalText:string,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>    
  ) => void;
}
const SampleStandardsVerificationRequestsListRow = ({
  sampleStandardsVerificationRequest,
  onSelect,
  handleClick,
  lpExclusions
}: SampleStandardsVerificationRequestListRowProps) => {
  const sampleStatus = sampleStandardsVerificationRequest && sampleStandardsVerificationRequest.status && sampleStandardsVerificationRequest.status.includes('_') ? TextHelper.titleCaseUploadStatus(sampleStandardsVerificationRequest.status) : sampleStandardsVerificationRequest.status;
 
  const lpExclusionsModalText= `The request for ${sampleStandardsVerificationRequest.learningProviderCode && sampleStandardsVerificationRequest.learningProviderCode} ${sampleStandardsVerificationRequest.learningProviderName && sampleStandardsVerificationRequest.learningProviderName} ${sampleStandardsVerificationRequest.qualificationGroupCode && sampleStandardsVerificationRequest.qualificationGroupCode} ${sampleStandardsVerificationRequest.qualificationName && sampleStandardsVerificationRequest.qualificationName} is associated with a Learning Provider on your exclusion list.  The request cannot be selected.`
  let SampleRequestFitered : any;
  lpExclusions.map( lp => 
      {
        if(sampleStandardsVerificationRequest.learningProviderId === lp){
          SampleRequestFitered = true
        }
        return SampleRequestFitered
      })

  return (
  
    <tr
      onClick = {(e) => handleClick(
        sampleStandardsVerificationRequest.status,
        sampleStandardsVerificationRequest.allocationId,
        SampleRequestFitered,
        lpExclusionsModalText,
        e
      )} 
      className={sampleStandardsVerificationRequest.allocationId === localStorage.getItem('selectedSVSampleRequest')?'rowHighlight':''}
    >
      <td>
        {sampleStandardsVerificationRequest.parentLearningProviderCode ? sampleStandardsVerificationRequest.parentLearningProviderCode : sampleStandardsVerificationRequest.learningProviderCode}
        
      </td>
      <td>
        {sampleStandardsVerificationRequest.learningProviderName}&nbsp;
        <b>{sampleStandardsVerificationRequest.consortiaId ? '(C)': '' }</b>
      </td>
      <td>
        {sampleStandardsVerificationRequest.qualificationGroupCode}
      </td>
      <td>
        {sampleStandardsVerificationRequest.qualificationName}&nbsp;
        <b className="second-sample-text-color">{sampleStandardsVerificationRequest.parentRequests && sampleStandardsVerificationRequest.parentRequests.length > 0 ? "("+(sampleStandardsVerificationRequest.parentRequests.length + 1)+")" : ""}</b>
      </td>
      <td>{sampleStandardsVerificationRequest.deadLineDateTime && (
        <FormattedDate
          value={sampleStandardsVerificationRequest.deadLineDateTime}
          year="numeric"
          month="short"
          day="numeric"
      /> ) }     
      </td>
      <td>
      {/* {TextHelper.titleCaseUploadStatus(
          sampleStandardsVerificationRequest.status
        ) === 'Request Received' && 
          <PearsonElementsSDK.Icon name="info-fill-18" />  
      }&nbsp;         */}
        { sampleStatus === 'Request Submitted' && sampleStandardsVerificationRequest.failureReason === 'Recall Failed'  ? 
          <Tooltip
            className="tooltip-top-rejected"
            id="tooltip-top"
            placement="top"
            maxWidth="400px"
            value="This request cannot be recalled because the learning provider has already submitted the request and checks are taking place."
          >
            <span className="tooltiptext-sample-recall-failed">
              <img src={infoFillImage} />
            </span>
          </Tooltip>

          : ''}

        {sampleStatus}
      </td>
    </tr>

   
  );
};

export default SampleStandardsVerificationRequestsListRow;
