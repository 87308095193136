export enum statusEnums {
    CREATE_REQUEST               = 'Create request',
    REQUEST_CREATION_IN_PROGRESS = 'Request Creation in Progress',
    REQUEST_RECEIVED             = 'Request Received',
    REQUEST_SUBMITTED            = 'Request Submitted',
    RECALL_PENDING              = 'Recall Pending',
    REQUEST_RECALLED             = 'Request Recalled',
    REQUEST_COMPLETED           = 'Request Completed',
}
export enum userTypeEnums {
    INTERNAL_USER = 'INTERNAL',
    EXTERNAL_USER = 'EXTERNAL'
}

export enum browserVersionEnums {
    CHROME = 76,
    FIREFOX = 69,
    SAFARI = 13
}

