import * as types from '../actions/action-types';
import { GlobalErrorActions } from '../actions/global-error.actions';
import initialState from './initialState';
// import * as toastr from 'toastr'

export function globalErrorReducer(
  state: boolean = initialState.hasGlobalError,
  action: GlobalErrorActions
): boolean {
  if (action.type === types.GLOBAL_ERROR) {
    // toastr.error(action.error.message);
    console.log(action.error.message);
    return true;
  } 
  return false;
}

export default globalErrorReducer;