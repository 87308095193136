import * as React from 'react';
import { userTypeEnums} from '../shared/enums';
import {Button} from 'ukas.uux.react-components';

// const PearsonElementsSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');
interface ConfirmationModalProps {
    cancelBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
    userType: string;
}


const AccessDeniedModal = ({ cancelBtnHandler, userType }: ConfirmationModalProps, ) =>{
  const errorMessageFragment = (userType.toUpperCase() === userTypeEnums.EXTERNAL_USER.toUpperCase())
    ? <> If you think you should have access, please contact 
      <a target="_blank" href="https://qualifications.pearson.com/en/contact-us/pearson-associate.html"> Associate Helpdesk.</a> </>
    : (userType.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase()) 
    ? 'If you think you should have access, please go to myStore and raise a PQS Requests incident.': 'Logging out...please wait';
  return ( 
    <div>
      <div >
            You do not have the correct profile to access this application.<br /><br /> 
            <span>{errorMessageFragment}</span>
            </div>
            <div className="pull-right"><br /><br />
            <Button
                onClick={(e:React.UIEvent<HTMLSpanElement>) => cancelBtnHandler(e)}
            >
                Close
            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
            <p>&nbsp;</p>

      </div>
    </div>
     
      
   
  );
};

export default AccessDeniedModal;