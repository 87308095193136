import * as React from 'react';
import {Button} from 'ukas.uux.react-components';
// const PearsonElementsSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');
interface ConfirmationModalProps {
    cancelBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
    successBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
}


const RecallConfirmationModal = ({ successBtnHandler, cancelBtnHandler }: ConfirmationModalProps) =>{
  return ( 
    <div>
      <div >
      <span>Any changes made to this request will impact the learning provider.</span>
      <br /><br />
      <p>If unit(s)/learner(s) are removed from this request, any uploaded material associated with the impacted unit(s)/learner(s) may also be removed.</p> 

      <span>Do you want to proceed?</span>
      </div>
      
      <div className="pull-right"><br /><br />
      <Button
            onClick={cancelBtnHandler.bind(event)}
            
            // disabled={isDisabled ? "disabled" : ""}
            >
            Cancel
            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="primary" onClick={successBtnHandler.bind(event)}>
            Ok
        </Button>
        <br /><br />
            
        
      </div>
    </div>
     
      
   
  );
};

export default RecallConfirmationModal;
