import * as React from 'react';
// const PearsonElementsSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');
import { Select, TextInput, Table } from 'ukas.uux.react-components';
import AssessmentAssociateAvailabilityListRow from './sample-standards-verification-requests-list-row';
import { SampleRequest } from '../../../models/sample-request';
import moment from 'moment';

interface SampleStandardsVerificationRequestsListProps {
  sampleRequests: SampleRequest[];
  onSort: (item: string) => void;
  lpExclusions: string[]
  getIconName: (columnName: string) => string;
  onFieldChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onSelect: (allocationId: string, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  handleClick: (SVStatus: string, SVAllocationId: string, filteredList: boolean, lpExclusionsModalText: string, event: any) => void;
  onYearChange: (event: React.FormEvent<HTMLSelectElement>) => void;
  displaySelect: boolean;
}

const SampleStandardsVerificationRequestsList = ({
  sampleRequests,
  onSort,
  lpExclusions,
  getIconName,
  onFieldChange,
  onSelect,

  handleClick,
  onYearChange,
  displaySelect
}: SampleStandardsVerificationRequestsListProps) => {
  const checkYear = moment();

  var month: string = checkYear.format('M');
  // var year  = checkYear.format('YYYY');
  let getYear = checkYear.format('YYYY');
  if (parseInt(month) < 9) {
    let nextyear = (moment().subtract(1, 'years'));
    getYear = nextyear.format('YYYY');
  }

  // let currentYear = "("+(parseInt(getYear)-1)+"/"+getYear+")";
  // let prevYear = "("+(parseInt(getYear)-2)+"/"+(parseInt(getYear)-1)+")";

  const currentYear = "(" + (parseInt(getYear)) + "/" + (parseInt(getYear) + 1) + ")";
  const prevYear = "(" + (parseInt(getYear) - 1) + "/" + (parseInt(getYear)) + ")";

  const currentYearTxt = 'Current Year ' + currentYear;
  const prevYearTxt = 'Previous Year ' + prevYear;
  const selectedYear = (localStorage.getItem('selectedYear') === getYear) ? currentYearTxt : prevYearTxt;

  return (
    <div>


      <div className="pe-table-toolbar">
        <div className="pull-left">
          {' '}
          <TextInput id="searchTerm"
          label="Search" onChange={onFieldChange} maxLength={10}          />
        </div>
        {displaySelect === true && (
          <div className="pull-right">
            {' '}

            {/* <Select id="academicYear"
              label="Select Academic Year" options={[currentYearTxt, prevYearTxt]} onChange={onYearChange} selectedOption={selectedYear}

            /> */}
            <Select id="academicYear"
              label="Select Academic Year" onChange={onYearChange} value={selectedYear}
              >
              <option
              value={prevYearTxt}
            >
              {prevYearTxt}
            </option>
            <option
              value={currentYearTxt}
            >
              {currentYearTxt}
            </option>
            </Select>
          </div>)}
        <div className="clearfix" />
      </div>

      <Table responsive="xl">
        <thead>
          <tr>
            <td>Learning Provider Code</td>
            <td>Learning provider</td>
            <td>Qualification Group Code</td>
            <td>Qualification Group</td>
            <td>Deadline</td>
            <td>Status</td>

          </tr>
        </thead>
        <tbody>
          {sampleRequests.map((SampleRequests: SampleRequest) => (
            <AssessmentAssociateAvailabilityListRow
              key={SampleRequests.allocationId}
              onSelect={onSelect}
              handleClick={handleClick}
              lpExclusions={lpExclusions}
              sampleStandardsVerificationRequest={SampleRequests}

            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SampleStandardsVerificationRequestsList;
