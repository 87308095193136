import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as _ from 'lodash';

import {
  AccessLearnerMaterial,
  StateProps,
  DispatchProps,
  OwnProps
} from './access-learner-material.component';

import { 
  SampleMediaRequestsAction,
  getLearnerMediaRequests,
  rejectSampleRequestAction 
} from './ducks/access-learner-material.actions';
import { StoreState } from '../../../store/store-state';
import { MediaRequest }  from '../../../models/media-request';
import { SampleRequest } from "../../../models/sample-request";

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => { 
  const sampleRequest = _.find(state.sampleRequests, {
    allocationId: ownProps.match.params.allocationId
  }) as SampleRequest

  const sampleMediaRequest:MediaRequest = state.sampleMediaRequests[0] || {}; 
  
  return {
    sampleRequest,
    sampleMediaRequest
  };
};

const mapDispatchToProps = (
  dispatch: 
  ThunkDispatch<
  StoreState,
  void,
  SampleMediaRequestsAction>
): DispatchProps => ({
    getLearnerMediaRequests: (learningProviderId: string, mediaSetId: string) =>
        dispatch(getLearnerMediaRequests(learningProviderId, mediaSetId)),
    rejectSampleRequest: (assessmentAssociateId:string, standardsVerificationSampleId: string) =>
      dispatch(rejectSampleRequestAction(assessmentAssociateId, standardsVerificationSampleId))
       
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(AccessLearnerMaterial);
