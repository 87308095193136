interface Config {
    API_GATEWAY: { URL: string };
    ORIGIN: string;
    APP_NAME: string;
    FORGEROCK: {
      AUTHORIZE_ENDPOINT: string
      SESSION_END_ENDPOINT: any;
      TOKEN_ENDPOINT: string
      CLIENT_ID: string;
      SCOPES: string[],
      REDIRECT_URI: string,
      TIMEOUT: number;
    };
    MEDIA_FILES : { MAX_FILE_SIZE: number };
    HELP_LINKS : { HELP: string, HELP_INTERNAL:string, TERMS_OF_USE: string, PRIVACY_POLICY: string };
    IS_MOCK: {MOCK_USERS: boolean, MOCK_FR: boolean, MOCK_USERS_INTERNAL:boolean};
    SALT: string; // This salt is harcoded in src/server/routes/permissions.ts
    SourceFileExtension:string[];
    refreshTime: number;
    refreshTime2: number;
    refreshTime3: number;
  }
  
  const dev: Config = {
    API_GATEWAY: {
      URL: 'http://localhost:3001'
    },
    ORIGIN: "http://localhost:3000",
    APP_NAME: 'Standards Verification',
    FORGEROCK: {
      CLIENT_ID: '3ak7o0i0nt95s8dgr695b2t66a',
      TIMEOUT: 3600000,
      SCOPES: ['openid', 'profile', 'email'],
      AUTHORIZE_ENDPOINT: 'https://pearson-shared-acc-dev1.auth.eu-west-2.amazoncognito.com/oauth2/authorize',
      TOKEN_ENDPOINT: 'https://pearson-shared-acc-dev1.auth.eu-west-2.amazoncognito.com/oauth2/token',
      REDIRECT_URI: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/callback`,
      SESSION_END_ENDPOINT: 'https://pearson-shared-acc-dev1.auth.eu-west-2.amazoncognito.com/logout',
    },
    MEDIA_FILES : {
      MAX_FILE_SIZE: 800
    },
    HELP_LINKS : {
      HELP: 'https://qualifications.pearson.com/en/contact-us/pearson-associate.html',
      HELP_INTERNAL: 'https://pearsonnow.service-now.com/esc',
      TERMS_OF_USE: 'https://www.pearson.com/uk/legal-notice.html',
      PRIVACY_POLICY: 'https://www.pearson.com/corporate/privacy-notice.html'      
    },
    IS_MOCK: {
      MOCK_FR: false, 
      MOCK_USERS: false,
      MOCK_USERS_INTERNAL: false,
    },
    SALT: 'd3O0w9b1l6sd6F3Efeq',
    SourceFileExtension:["xls","xlsx","xlsm","xlsb","zip","exe","rar","vod","wav","midi","xml","pdf","mp3","mp4"],
    refreshTime:60000,
    refreshTime2:30000,
    refreshTime3:15000
  };
  
  const prod: Config = {
    API_GATEWAY: {
      URL: '**ENVIRONMENT_SERVER_URL**'
    },
    APP_NAME: 'Standards Verification',
    ORIGIN:`${window.location.protocol}//${window.location.hostname}`,
    FORGEROCK: {
      CLIENT_ID: '**COGNITO_APP_CLIENT_ID**',
      TIMEOUT: 3600000,
      SCOPES: ['openid', 'profile', 'email'],
      REDIRECT_URI: `${window.location.protocol}//${window.location.hostname}/callback`,
      AUTHORIZE_ENDPOINT: '**COGNITO_AUTH_BASE_URL**/oauth2/authorize',
      SESSION_END_ENDPOINT: '**COGNITO_AUTH_BASE_URL**/logout',
      TOKEN_ENDPOINT: '**COGNITO_AUTH_BASE_URL**/oauth2/token',
    },
    MEDIA_FILES : {
      MAX_FILE_SIZE: 800
    },
    HELP_LINKS : {
      HELP: 'https://qualifications.pearson.com/en/contact-us/pearson-associate.html',
      HELP_INTERNAL: 'https://pearsonnow.service-now.com/esc',
      TERMS_OF_USE: 'https://www.pearson.com/uk/legal-notice.html',
      PRIVACY_POLICY: 'https://www.pearson.com/corporate/privacy-notice.html'
    },
    IS_MOCK: {
      MOCK_FR: false, 
      MOCK_USERS: false,
      MOCK_USERS_INTERNAL: false,
    },
    SALT: 'd3O0w9b1l6sd6F3Efeq',
    SourceFileExtension:["xls","xlsx","xlsm","xlsb","zip","exe","rar","vod","wav","midi","xml","pdf","mp3","mp4"],
    refreshTime:60000,
    refreshTime2:30000,
    refreshTime3:15000
  };
  
  // @ts-ignore
  const config: Config = process.env.NODE_ENV === 'production' ? prod : dev;

  export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
  };
  