import * as React from "react";
import LearnerSelectionListRow from "./learner-selection-list-row";
import  Learners  from '../../../models/learner';
import { statusEnums } from "../../shared/enums";
// import { SampleRequest } from "../../../../models/sample-request";
import {Button,TextInput,Table, Checkbox} from 'ukas.uux.react-components';
// const PearsonElementsSDK = require("@pearson-components/elements-sdk/build/dist.elements-sdk");

interface LearnerSelectionListProps {
  onSelect: (
    LearnerId: string,
    Learner: Learners,
    singlesampleRequest: object,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  onSort: (item: string) => void;
  getIconName: (columnName: string) => string;
  learners: Learners[];
  singleSampleStandardsVerificationRequest: object;
  selectedLearners: string[];
  closeEvent: () => void;
  display: boolean;
  readOnly: boolean;
  onSelectCheckAll:(event: React.MouseEvent<HTMLSpanElement, MouseEvent>  ) => void;
  checkBoxAll:boolean;
  onFieldChange: (event: React.FormEvent<HTMLInputElement>) => void;
  isConsortia: boolean;
  isTeamMember: boolean;
}


const LearnerSelectionList = ({
  onSelect,
  onSort,
  getIconName,
  learners,
  singleSampleStandardsVerificationRequest,
  selectedLearners,
  closeEvent,
  display,
  readOnly,
  onSelectCheckAll,
  checkBoxAll,
  onFieldChange,
  isConsortia,
  isTeamMember
}: LearnerSelectionListProps) => (
 
  <div>
    <div className="pe-table-toolbar">
      <div className="pull-left">
          {' '}
      <TextInput id="searchTerm"
        label="Search" 
        onChange={onFieldChange} 
        maxLength={10} />
      </div>
      <div className="clearfix" />
    </div>
    <br />
    <div className="scrollTable">
    <Table >
      <thead>
        <tr>
          <td>Learner Provider Code</td>
          <td>Learner Provider</td>
          <td>Learner Code</td>
          <td>Learner</td>
          <td>Date of Birth</td>
         
          {/* <PearsonElementsSDK.TableHeaderCell
            inputLabel="Gender"
            columnSort={onSort("Gender")}
            iconName={getIconName("Gender")} learnerIds= {learnerIds}
          /> */}
          
          { (!readOnly && !isTeamMember) && (
            <th><div className="pe-checkbox" onClick={e => { onSelectCheckAll(e)}}>
            
            <Checkbox
                checked={checkBoxAll}
                disabled={status === statusEnums.REQUEST_COMPLETED}
                aria-label="this is an aria-label"
              />  <span>Select</span>
          </div></th>
          )}
          </tr>
        </thead>
        <tbody>
        
      
          {learners.map((learnerDetails: Learners) => (
            <LearnerSelectionListRow
              key={learnerDetails.learnerId}
              onSelect={onSelect}
              LearnerSelectionList={learnerDetails}
              singleSampleStandardsVerificationRequest={
                singleSampleStandardsVerificationRequest
              }
              selectedLearners={selectedLearners}
              display={display}
              readOnly = {readOnly}
              isConsortia ={isConsortia}
              isTeamMember = {isTeamMember}
              
            />
          ))}          
        </tbody>
      </Table>
    
    </div>
    <div className="pull-right"><br />
      <Button
        onClick={closeEvent}
        variant="primary"       
      >
        Close
      </Button>
    </div>
  </div>
);

export default LearnerSelectionList;
