import * as React from 'react';
import {Button} from 'ukas.uux.react-components';
// const PearsonElementsSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');
interface ConfirmationModalProps {
    cancelBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
    successBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
}


const ConfirmationModal = ({ successBtnHandler, cancelBtnHandler }: ConfirmationModalProps) =>{
  return ( 
    <div>
      <div >
        <p>
            This sample will be returned to the learning provider for amendments to be made.  
            Until the learning provider resubmits the sample you cannot access any of the learner material.            
        </p>
        <span>Do you want to proceed?</span>
      </div>
      
      <div className="pull-right"><br /><br />
      <Button
            onClick={cancelBtnHandler.bind(event)}
            
            // disabled={isDisabled ? "disabled" : ""}
            >
            Cancel
            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="primary" onClick={successBtnHandler.bind(event)}>
            Ok
        </Button>
        <br /><br />
            
        
      </div>
    </div>
     
      
   
  );
};

export default ConfirmationModal;
