export const GLOBAL_ERROR = 'GLOBAL_ERROR';
export type GLOBAL_ERROR = typeof GLOBAL_ERROR;

export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export type BEGIN_AJAX_CALL = typeof BEGIN_AJAX_CALL;

export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export type AJAX_CALL_ERROR = typeof AJAX_CALL_ERROR;

export const AUTH_USER = 'AUTH_USER';
export type AUTH_USER = typeof AUTH_USER;

export const UNAUTH_USER = 'UNAUTH_USER';
export type UNAUTH_USER = typeof UNAUTH_USER;

export const AUTH_IN_PROGRESS = 'AUTH_IN_PROGRESS';
export type AUTH_IN_PROGRESS = typeof AUTH_IN_PROGRESS;

export const AUTH_ERROR = 'AUTH_ERROR';
export type AUTH_ERROR = typeof AUTH_ERROR;

export const SELECT_AA_TEAM_SUCCESS = 'SELECT_AA_TEAM_SUCCESS';
export type SELECT_AA_TEAM_SUCCESS = typeof SELECT_AA_TEAM_SUCCESS;

export const IS_AA_TEAM_LEAD_SUCCESS = 'IS_AA_TEAM_LEAD_SUCCESS';
export type IS_AA_TEAM_LEAD_SUCCESS = typeof IS_AA_TEAM_LEAD_SUCCESS;

export const GET_AA_TEAM_LEAD_DETAILS_SUCCESS = 'GET_AA_TEAM_LEAD_DETAILS_SUCCESS';
export type GET_AA_TEAM_LEAD_DETAILS_SUCCESS = typeof GET_AA_TEAM_LEAD_DETAILS_SUCCESS;

export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export type FORGOT_SUCCESS = typeof FORGOT_SUCCESS;

export const CLEARDOWN = 'CLEARDOWN';
export type CLEARDOWN = typeof CLEARDOWN;

export const CALLBACK_IN_PROGRESS = 'CALLBACK_IN_PROGRESS';
export type CALLBACK_IN_PROGRESS = typeof CALLBACK_IN_PROGRESS;

export const CALLBACK_COMPLETE = 'CALLBACK_COMPLETE';
export type CALLBACK_COMPLETE = typeof CALLBACK_COMPLETE;

export const GET_ENV_SUCCESS = 'GET_ENV_SUCCESS';
export type GET_ENV_SUCCESS = typeof GET_ENV_SUCCESS;

export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export type GET_USER_DETAILS_SUCCESS = typeof GET_USER_DETAILS_SUCCESS;

export const GET_ASSESSMENTASSOCIATE_DETAILS_SUCCESS = 'GET_ASSESSMENTASSOCIATE_DETAILS_SUCCESS';
export type GET_ASSESSMENTASSOCIATE_DETAILS_SUCCESS = typeof GET_ASSESSMENTASSOCIATE_DETAILS_SUCCESS;

export const VALIDATE_USER_PERMISSIONS_SUCCESS = 'VALIDATE_USER_PERMISSIONS_SUCCESS';
export type VALIDATE_USER_PERMISSIONS_SUCCESS = typeof VALIDATE_USER_PERMISSIONS_SUCCESS;

export const GET_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS = 'GET_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS';
export type GET_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS = typeof GET_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS;

export const GET_SAMPLE_MEDIA_REQUESTS_COMPLETED = 'GET_SAMPLE_MEDIA_REQUESTS_COMPLETED';
export type GET_SAMPLE_MEDIA_REQUESTS_COMPLETED = typeof GET_SAMPLE_MEDIA_REQUESTS_COMPLETED;

export const GET_SAMPLE_MEDIA_REQUESTS_STARTED = 'GET_SAMPLE_MEDIA_REQUESTS_STARTED';
export type GET_SAMPLE_MEDIA_REQUESTS_STARTED = typeof GET_SAMPLE_MEDIA_REQUESTS_STARTED;

export const UPDATE_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS = 'UPDATE_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS';
export type UPDATE_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS = typeof UPDATE_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS;

export const MODIFY_SELECTED_SAMPLE_STANDARDS_VERIFICATION_UNITS_SUCCESS = 'MODIFY_SELECTED_SAMPLE_STANDARDS_VERIFICATION_UNITS_SUCCESS';
export type MODIFY_SELECTED_SAMPLE_STANDARDS_VERIFICATION_UNITS_SUCCESS = typeof MODIFY_SELECTED_SAMPLE_STANDARDS_VERIFICATION_UNITS_SUCCESS;

export const GET_STANDARDS_VERIFICATION_UNITS_SUCCESS = 'GET_STANDARDS_VERIFICATION_UNITS_SUCCESS';
export type GET_STANDARDS_VERIFICATION_UNITS_SUCCESS = typeof GET_STANDARDS_VERIFICATION_UNITS_SUCCESS;

export const GET_LEARNER_MEDIA_REQUESTS_SUCCESS = 'GET_LEARNER_MEDIA_REQUESTS_SUCCESS';
export type GET_LEARNER_MEDIA_REQUESTS_SUCCESS = typeof GET_LEARNER_MEDIA_REQUESTS_SUCCESS;

export const CREATE_STANDARDS_VERIFICATION_SET_SUCCESS = 'CREATE_STANDARDS_VERIFICATION_SET_SUCCESS';
export type CREATE_STANDARDS_VERIFICATION_SET_SUCCESS = typeof CREATE_STANDARDS_VERIFICATION_SET_SUCCESS;

export const SUBMIT_STANDARDS_VERIFICATION_SET_SUCCESS = 'SUBMIT_STANDARDS_VERIFICATION_SET_SUCCESS';
export type SUBMIT_STANDARDS_VERIFICATION_SET_SUCCESS = typeof SUBMIT_STANDARDS_VERIFICATION_SET_SUCCESS;

export const CREATE_STANDARDS_VERIFICATION_FILE_SET_SUCCESS = 'CREATE_STANDARDS_VERIFICATION_FILE_SET_SUCCESS';
export type CREATE_STANDARDS_VERIFICATION_FILE_SET_SUCCESS = typeof CREATE_STANDARDS_VERIFICATION_FILE_SET_SUCCESS;


export const UPLOAD_STANDARDS_VERIFICATION_SUCCESS = 'UPLOAD_STANDARDS_VERIFICATION_SUCCESS';
export type UPLOAD_STANDARDS_VERIFICATION_SUCCESS = typeof UPLOAD_STANDARDS_VERIFICATION_SUCCESS;

export const UPLOAD_STANDARDS_VERIFICATION_PROGRESS = 'UPLOAD_STANDARDS_VERIFICATION_PROGRESS';
export type UPLOAD_STANDARDS_VERIFICATION_PROGRESS = typeof UPLOAD_STANDARDS_VERIFICATION_PROGRESS;

export const UPLOAD_STANDARDS_VERIFICATION_CANCELLED = 'UPLOAD_STANDARDS_VERIFICATION_CANCELLED';
export type UPLOAD_STANDARDS_VERIFICATION_CANCELLED = typeof UPLOAD_STANDARDS_VERIFICATION_CANCELLED

export const UPLOAD_STANDARDS_VERIFICATION_ERROR = 'UPLOAD_STANDARDS_VERIFICATION_ERROR';
export type UPLOAD_STANDARDS_VERIFICATION_ERROR = typeof UPLOAD_STANDARDS_VERIFICATION_ERROR


export const GET_ASESSMENT_ASSOCIATE_SUCCESS = 'GET_ASESSMENT_ASSOCIATE_SUCCESS';
export type GET_ASESSMENT_ASSOCIATE_SUCCESS = typeof GET_ASESSMENT_ASSOCIATE_SUCCESS;

export const LOAD_AVAILABILITIES_SUCCESS = 'LOAD_AVAILABILITIES_SUCCESS';
export type LOAD_AVAILABILITIES_SUCCESS = typeof LOAD_AVAILABILITIES_SUCCESS; 

export const GET_STANDARDS_VERIFICATION_LEARNERS_SUCCESS = 'GET_STANDARDS_VERIFICATION_LEARNERS_SUCCESS';
export type GET_STANDARDS_VERIFICATION_LEARNERS_SUCCESS = typeof GET_STANDARDS_VERIFICATION_LEARNERS_SUCCESS; 

export const  ADD_LEARNER_SUCCESS = 'ADD_LEARNER_SUCCESS';
export type ADD_LEARNER_SUCCESS = typeof ADD_LEARNER_SUCCESS;

export const  REMOVE_LEARNER_SUCCESS = 'REMOVE_LEARNER_SUCCESS';
export type REMOVE_LEARNER_SUCCESS = typeof REMOVE_LEARNER_SUCCESS;

export const MODIFY_SELECTED_SAMPLE_STANDARDS_VERIFICATION_LEARNERS_SUCCESS = 'MODIFY_SELECTED_SAMPLE_STANDARDS_VERIFICATION_LEARNERS_SUCCESS';
export type MODIFY_SELECTED_SAMPLE_STANDARDS_VERIFICATION_LEARNERS_SUCCESS =  typeof MODIFY_SELECTED_SAMPLE_STANDARDS_VERIFICATION_LEARNERS_SUCCESS;

export const ADD_GLOBAL_ALERT = 'ADD_GLOBAL_ALERT';
export type ADD_GLOBAL_ALERT = typeof ADD_GLOBAL_ALERT;

export const REMOVE_GLOBAL_ALERT = 'REMOVE_GLOBAL_ALERT';
export type REMOVE_GLOBAL_ALERT = typeof REMOVE_GLOBAL_ALERT;

export const UPDATE_PROXT_AA_TEAM_MEMBER = 'UPDATE_PROXT_AA_TEAM_MEMBER';
export type UPDATE_PROXT_AA_TEAM_MEMBER = typeof UPDATE_PROXT_AA_TEAM_MEMBER;